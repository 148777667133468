<style scoped lang="less">
.top-item-row {
  margin: 10px 15px;
  display: flex;
  justify-content: space-between;
}
.item-row {
  width: 30%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.item-row > span {
  margin: 0 15px;
}
.button-panel {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
</style>

<template>
  <CmDialogBorder size="small" :zIndex="zIndex" title="房颤冷冻" v-show="isShow" @close="Close">
    <div class="top-item-row">
      <div class="item-row">
        <span>冷冻部位（LSPV|LIPV|RSPV|RIPV）</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="string"
          v-model="Freezing.freezingSite"
          size="mini"
        ></el-input>
      </div>

      <div class="item-row">
        <span>30s温度(℃)</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          v-model="Freezing.temperature30"
          size="mini"
        ></el-input>
      </div>

      <div class="item-row">
        <span>60s温度(℃)</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          v-model="Freezing.temperature60"
          size="mini"
        ></el-input>
      </div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>冷冻时间</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          v-model="Freezing.freezingTime"
          size="mini"
        ></el-input>
      </div>

      <div class="item-row">
        <span>PVI时间（s）</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          v-model="Freezing.pvitime"
          size="mini"
        ></el-input>
      </div>

      <div class="item-row">
        <span>PVI温度(℃)</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          v-model="Freezing.pvitemperature"
          size="mini"
        ></el-input>
      </div>
      <div class="item-row">
        <span>最低温度(℃)</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          v-model="Freezing.minTemperature"
          size="mini"
        ></el-input>
      </div>
    </div>

    <div class="button-panel">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import deepClone from '../../../../plugins/clone'

export default {
  name: 'Freezing',
  components: {
    CmDialogBorder
  },
  props: {
    id: {
      type: [Number, String],
      require: true
    }
  },
  data() {
    return {
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      Freezing: {
        electrophysiologyIndex: 0,
        freezingOrder: null,
        freezingSite: null,
        freezingTime: null,
        minTemperature: null,
        pvitemperature: null,
        pvitime: null,
        temperature30: null,
        temperature60: null
      }
    }
  },
  methods: {
    Show(val) {
      this.Freezing = {}
      this.isShow = true
      if (val) {
        this.Freezing = deepClone(val)
      }
    },
    Save() {
      if (!this.Freezing.freezingSite) {
        return this.$message.warning('冷冻部位不能为空')
      }
      this.$emit('freezingSave', this.Freezing)
      this.Close()
    },
    Close() {
      this.isShow = false
    }
  }
}
</script>
