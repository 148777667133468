<style scoped lang="less">
.top-item-row {
  margin: 10px 15px;
  display: flex;
  justify-content: space-between;
}
.item-row {
  width: 30%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.item-row > span {
  margin: 0 15px;
}
.button-panel {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
</style>

<template>
  <CmDialogBorder
    size="small"
    :zIndex="zIndex"
    title="术前检查心房刺激"
    v-show="isShow"
    @close="Close"
  >
    <div class="top-item-row">
      <div class="item-row">
        <span>诱发方式</span>
        <cm-select
          activeUrl="/v1/webconsole/comboData/get/EP_InductTypeAPre"
          :expand="false"
          v-model="EPDgvTachInductAPre.inductTypeAPre"
        ></cm-select>
      </div>

      <div class="item-row">
        <span>周长（ms）</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          v-model="EPDgvTachInductAPre.perimAPre"
          size="mini"
        ></el-input>
      </div>

      <div class="item-row">
        <span>诱发结果</span>
        <cm-select
          activeUrl="/v1/webconsole/comboData/get/EP_InductResultAPre"
          :expand="false"
          v-model="EPDgvTachInductAPre.inductResultAPre"
        ></cm-select>
      </div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>诱发的心动过速类型</span>
        <cm-select
          activeUrl="/v1/webconsole/comboData/get/EP_TachInductTypeAPre"
          :expand="false"
          v-model="EPDgvTachInductAPre.tachInductTypeAPre"
        ></cm-select>
      </div>

      <div class="item-row">
        <span>冠状窦激动顺序</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          v-model="EPDgvTachInductAPre.activeSequenceAPre"
          size="mini"
        ></el-input>
      </div>

      <div class="item-row">
        <span>VA关系</span>
        <cm-select
          activeUrl="/v1/webconsole/comboData/get/EP_VARelationAPre"
          :expand="false"
          v-model="EPDgvTachInductAPre.vARelationAPre"
        ></cm-select>
      </div>
    </div>

    <div class="button-panel">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="open_Save"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import CmSelect from '../../../../components/CmSelect.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import deepClone from '../../../../plugins/clone'

export default {
  name: 'PreAtrialDialog',
  components: {
    CmDialogBorder,
    CmSelect
  },
  props: {
    id: {
      type: [Number, String],
      require: true
    },
    initDefaultData: {
      // 初始化数据
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      EPDgvTachInductAPre: {
        activeSequenceAPre: null,
        electrophysiologyIndex: '',
        inductResultAPre: null,
        inductTypeAPre: null,
        perimAPre: null,
        tachInductTypeAPre: null,
        vARelationAPre: null
      }
    }
  },
  watch: {
    initDefaultData: {
      handler(val) {
        if (val) {
          for (let i in val) {
            for (let j in this.EPDgvTachInductAPre) {
              if (j === i) {
                this.EPDgvTachInductAPre[i] = val[i]
              }
            }
          }
          console.log(this.EPDgvTachInductAPre)
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    Show(val) {
      this.isShow = true
      if (val) {
        this.EPDgvTachInductAPre = deepClone(val)
      } else if (!this.initDefaultData) {
        this.EPDgvTachInductAPre = {
          activeSequenceAPre: null,
          electrophysiologyIndex: '',
          inductResultAPre: null,
          inductTypeAPre: null,
          perimAPre: null,
          tachInductTypeAPre: null,
          vARelationAPre: null
        }
      }
    },
    open_Save() {
      this.$confirm('是否保存编辑信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.Save()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消保存'
          })
        })
    },
    Save() {
      console.log(11111)
      if (!this.EPDgvTachInductAPre.inductTypeAPre) {
        return this.$message.warning('诱发方式不能为空')
      }
      this.$emit('preASave', this.EPDgvTachInductAPre)
      this.Close()
    },

    Close() {
      this.isShow = false
    }
  }
}
</script>
