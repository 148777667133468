<style lang="scss" scoped>
.electrophysiology-main-content::-webkit-scrollbar {
  width: 12px;
}
.electrophysiology-main-content::-webkit-scrollbar-track {
  border-radius: 10px;
}
.electrophysiology-main-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
.electrophysiology-main-content::-webkit-scrollbar-thumb:window-inactive {
  background: #ccc;
}
.electrophysiology-main-content {
  flex: 1;
  overflow: auto;
  padding: 0 10px 20px;
  background: #eff0f7;

  .button-panel {
    width: 100%;
    bottom: 0;
    margin: 1.5rem 0 1rem 0;
    display: flex;
    justify-content: space-around;
  }

  .electrophysiology-basic-information {
    .electrophysiology-basic-information-body {
      display: flex;
      margin: 10px 0;
      .el-card {
        margin-right: 10px;
      }

      .el-card:last-child {
        margin: 0;
      }

      .input-form-card {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      .input-form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
      }

      .input-form-item {
        min-width: 28%;
        margin: 0.4rem 1.5rem;
        display: flex;
        align-items: center;

        .input_has_unit {
          width: 200px;
          margin: 0;
        }

        .input_has_unit_text {
          margin: 0;
          width: 280px;
        }

        .el-date-editor.el-input {
          width: 200px;
        }
      }

      .input-form-item {
        min-width: 30%;
        margin: 0.4rem 0 0.4rem 0.5rem;
        display: flex;
        align-items: center;
      }

      @media screen and (max-width: 1180px) {
        .input-form-item {
          min-width: 40%;
        }
      }

      .input-form-item-label {
        width: 120px;
      }
    }

    .input-form {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .input-form-item-row {
      width: 100%;
      margin: 0 0.5rem;
      display: flex;
      justify-content: flex-start;
    }

    .input-form-item {
      min-width: 28%;
      margin: 0.4rem 1.5rem;
      display: flex;
      align-items: center;
      .input_has_unit {
        margin: 0;
      }
    }
    @media screen and (max-width: 1180px) {
      .input-form-item {
        min-width: 40%;
      }
    }

    .input-form-item-label {
      width: 120px;
    }
  }
  .electrophysiology-radios {
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left: 10px;
  }
  .selectBox {
    padding: 10px 0;
  }
  .radios-add-components {
    .electrophysiology-pre-operative-examination {
      margin-top: 20px;
      margin-bottom: 30px;
      border-style: solid;
      border-width: medium;
      padding: 20px 15px;
      border-radius: 5px;
      position: relative;
      border-color: #ecf0f1;
      background-color: #fff !important;
      box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);
      .electrophysiology-pre-operative-examination-header {
        .inner-title {
          position: absolute;
          top: -12px;
          left: 12px;
          background: #fff;
          padding: 0 10px;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          padding-top: 5px;
        }
      }

      .electrophysiology-pre-operative-examination-body {
        .electrophysiology-pre-operative-examination-body-radios {
          padding: 10px 20px;
        }
        .ele-ventricular-stimulation {
          .ele-ventricular-stimulation-content {
            margin-top: 30px;
            margin-bottom: 30px;
            border-top: 1px solid #c0c4cc;
            .ele-ventricular-stimulation-content-head {
              .inner-title-label {
                position: relative;
                top: -12px;
                left: 12px;
                padding: 0 10px;
                font-size: 14px;
                text-align: center;
                font-weight: bold;
              }
            }

            .ele-ventricular-stimulation-content-body {
              .ele-ventricular-stimulation-content-body-select {
                .input-form {
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: flex-start;
                }

                .input-form-item-row {
                  width: 100%;
                  margin: 0 0.5rem;
                  display: flex;
                  justify-content: flex-start;
                }

                .input-form-item {
                  min-width: 28%;
                  margin: 0.4rem 1.5rem;
                  display: flex;
                  align-items: center;
                  .input_has_unit {
                    margin: 0;
                  }
                }
                @media screen and (max-width: 1180px) {
                  .input-form-item {
                    min-width: 40%;
                  }
                }

                .input-form-item-label {
                  width: 120px;
                }
              }
              .ele-ventricular-stimulation-content-body-table {
                .ele-ventricular-stimulation-content-body-table-button {
                  display: flex;
                }
              }
            }
          }
        }

        .ele-atrial-stimulation {
          .ele-atrial-stimulation-content {
            margin-top: 30px;
            margin-bottom: 30px;
            border-top: 1px solid #c0c4cc;
            .ele-atrial-stimulation-content-head {
              .inner-title-label {
                position: relative;
                top: -12px;
                left: 12px;
                padding: 0 10px;
                font-size: 14px;
                text-align: center;
                font-weight: bold;
              }
            }

            .ele-atrial-stimulation-content-body {
              .ele-atrial-stimulation-content-body-select {
                .input-form {
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: flex-start;
                }

                .input-form-item-row {
                  width: 100%;
                  margin: 0 0.5rem;
                  display: flex;
                  justify-content: flex-start;
                }

                .input-form-item {
                  min-width: 28%;
                  margin: 0.4rem 1.5rem;
                  display: flex;
                  align-items: center;
                  .input_has_unit {
                    margin: 0;
                  }
                }
                @media screen and (max-width: 1180px) {
                  .input-form-item {
                    min-width: 40%;
                  }
                }

                .input-form-item-label {
                  width: 120px;
                }
              }
              .ele-atrial-stimulation-content-body-table {
                .ele-atrial-stimulation-content-body-table-button {
                  display: flex;
                }
              }
            }
          }
        }
        .ele-atrial-drag-band {
          .ele-atrial-drag-band-content {
            margin-top: 30px;
            margin-bottom: 30px;
            border-top: 1px solid #c0c4cc;
            .ele-atrial-drag-band-content-head {
              .inner-title-label {
                position: relative;
                top: -12px;
                left: 12px;
                padding: 0 10px;
                font-size: 14px;
                text-align: center;
                font-weight: bold;
              }
            }

            .ele-atrial-drag-band-content-body {
              .ele-atrial-drag-band-content-body-select {
                .input-form {
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: flex-start;
                }

                .input-form-item-row {
                  width: 100%;
                  margin: 0 0.5rem;
                  display: flex;
                  justify-content: flex-start;
                }

                .input-form-item {
                  min-width: 28%;
                  margin: 0.4rem 1.5rem;
                  display: flex;
                  align-items: center;
                  .input_has_unit {
                    margin: 0;
                  }
                }
                @media screen and (max-width: 1180px) {
                  .input-form-item {
                    min-width: 40%;
                  }
                }

                .input-form-item-label {
                  width: 120px;
                }
              }
            }
          }
        }
        .ele-ventricular-drag-band {
          .ele-ventricular-drag-band-content {
            margin-top: 30px;
            margin-bottom: 30px;
            border-top: 1px solid #c0c4cc;
            .ele-ventricular-drag-band-content-head {
              .inner-title-label {
                position: relative;
                top: -12px;
                left: 12px;
                padding: 0 10px;
                font-size: 14px;
                text-align: center;
                font-weight: bold;
              }
            }

            .ele-ventricular-drag-band-content-body {
              .ele-ventricular-drag-band-content-body-select {
                .input-form {
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: flex-start;
                }

                .input-form-item-row {
                  width: 100%;
                  margin: 0 0.5rem;
                  display: flex;
                  justify-content: flex-start;
                }

                .input-form-item {
                  min-width: 28%;
                  margin: 0.4rem 1.5rem;
                  display: flex;
                  align-items: center;
                  .input_has_unit {
                    margin: 0;
                  }
                }
                @media screen and (max-width: 1180px) {
                  .input-form-item {
                    min-width: 40%;
                  }
                }

                .input-form-item-label {
                  width: 120px;
                }
              }
            }
          }
        }
        .ele-RS2 {
          .ele-RS2-content {
            margin-top: 30px;
            margin-bottom: 30px;
            border-top: 1px solid #c0c4cc;
            .ele-RS2-content-head {
              .inner-title-label {
                position: relative;
                top: -12px;
                left: 12px;
                padding: 0 10px;
                font-size: 14px;
                text-align: center;
                font-weight: bold;
              }
            }

            .ele-RS2-content-body {
              .ele-RS2-content-body-select {
                .input-form {
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: flex-start;
                }

                .input-form-item-row {
                  width: 100%;
                  margin: 0 0.5rem;
                  display: flex;
                  justify-content: flex-start;
                }

                .input-form-item {
                  min-width: 28%;
                  margin: 0.4rem 1.5rem;
                  display: flex;
                  align-items: center;
                  .input_has_unit {
                    margin: 0;
                  }
                }
                @media screen and (max-width: 1180px) {
                  .input-form-item {
                    min-width: 40%;
                  }
                }

                .input-form-item-label {
                  width: 120px;
                }
              }
            }
          }
        }
        .ele-hitchhiker-parabolic-stimulation {
          .ele-hitchhiker-parabolic-stimulation-content {
            margin-top: 30px;
            margin-bottom: 30px;
            border-top: 1px solid #c0c4cc;
            .ele-hitchhiker-parabolic-stimulation-content-head {
              .inner-title-label {
                position: relative;
                top: -12px;
                left: 12px;
                padding: 0 10px;
                font-size: 14px;
                text-align: center;
                font-weight: bold;
              }
            }

            .ele-hitchhiker-parabolic-stimulation-content-body {
              .ele-hitchhiker-parabolic-stimulation-content-body-select {
                .input-form {
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: flex-start;
                }

                .input-form-item-row {
                  width: 100%;
                  margin: 0 0.5rem;
                  display: flex;
                  justify-content: flex-start;
                }

                .input-form-item {
                  min-width: 28%;
                  margin: 0.4rem 1.5rem;
                  display: flex;
                  align-items: center;
                  .input_has_unit {
                    margin: 0;
                  }
                }
                @media screen and (max-width: 1180px) {
                  .input-form-item {
                    min-width: 40%;
                  }
                }

                .input-form-item-label {
                  width: 120px;
                }
              }
            }
          }
        }

        .ele-bypass-description {
          .ele-bypass-description-content {
            margin-top: 30px;
            margin-bottom: 30px;
            border-top: 1px solid #c0c4cc;
            .ele-bypass-description-content-head {
              .inner-title-label {
                position: relative;
                top: -12px;
                left: 12px;
                padding: 0 10px;
                font-size: 14px;
                text-align: center;
                font-weight: bold;
              }
            }

            .ele-bypass-description-content-body {
              .ele-bypass-description-content-body-select {
                .input-form {
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: flex-start;
                }

                .input-form-item-row {
                  width: 100%;
                  margin: 0 0.5rem;
                  display: flex;
                  justify-content: flex-start;
                }

                .input-form-item {
                  min-width: 28%;
                  margin: 0.4rem 1.5rem;
                  display: flex;
                  align-items: center;
                  .input_has_unit {
                    margin: 0;
                  }
                }
                @media screen and (max-width: 1180px) {
                  .input-form-item {
                    min-width: 40%;
                  }
                }

                .input-form-item-label {
                  width: 120px;
                }
              }
            }
          }
        }
        .ele-tachycardia-description {
          .ele-tachycardia-description-content {
            margin-top: 30px;
            margin-bottom: 30px;
            border-top: 1px solid #c0c4cc;
            .ele-tachycardia-description-content-head {
              .inner-title-label {
                position: relative;
                top: -12px;
                left: 12px;
                padding: 0 10px;
                font-size: 14px;
                text-align: center;
                font-weight: bold;
              }
            }

            .ele-tachycardia-description-content-body {
              .ele-tachycardia-description-body-select {
                .input-form {
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: flex-start;
                }

                .input-form-item-row {
                  width: 100%;
                  margin: 0 0.5rem;
                  display: flex;
                  justify-content: flex-start;
                }

                .input-form-item {
                  min-width: 28%;
                  margin: 0.4rem 1.5rem;
                  display: flex;
                  align-items: center;
                  .input_has_unit {
                    margin: 0;
                  }
                }
                @media screen and (max-width: 1180px) {
                  .input-form-item {
                    min-width: 40%;
                  }
                }

                .input-form-item-label {
                  width: 120px;
                }
              }
            }
          }
        }
      }
    }
    .electrophysiology-post-operative-examination {
      margin-top: 20px;
      margin-bottom: 30px;
      border-style: solid;
      border-width: medium;
      /* border-color: #c0c4cc; */
      padding: 20px 15px;
      border-radius: 5px;
      position: relative;
      border-color: #ecf0f1;
      background-color: #fff !important;
      box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);
      .electrophysiology-post-operative-examination-header {
        .inner-title {
          position: absolute;
          top: -12px;
          left: 12px;
          background: #fff;
          padding: 0 10px;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          padding-top: 5px;
        }
      }

      .electrophysiology-post-operative-examination-body {
        .electrophysiology-post-operative-examination-body-radios {
          padding: 10px 20px;
        }
        .ele-post-ventricular-stimulation {
          .ele-post-ventricular-stimulation-content {
            margin-top: 30px;
            margin-bottom: 30px;
            border-top: 1px solid #c0c4cc;
            .ele-post-ventricular-stimulation-content-head {
              .inner-title-label {
                position: relative;
                top: -12px;
                left: 12px;
                padding: 0 10px;
                font-size: 14px;
                text-align: center;
                font-weight: bold;
              }
            }

            .ele-post-ventricular-stimulation-content-body {
              .ele-post-ventricular-stimulation-body-select {
                .input-form {
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: flex-start;
                }

                .input-form-item-row {
                  width: 100%;
                  margin: 0 0.5rem;
                  display: flex;
                  justify-content: flex-start;
                }

                .input-form-item {
                  min-width: 28%;
                  margin: 0.4rem 1.5rem;
                  display: flex;
                  align-items: center;
                  .input_has_unit {
                    margin: 0;
                  }
                }
                @media screen and (max-width: 1180px) {
                  .input-form-item {
                    min-width: 40%;
                  }
                }

                .input-form-item-label {
                  width: 120px;
                }
              }
              .ele-post-ventricular-stimulation-content-body-table {
                .ele-post-ventricular-stimulation-content-body-table-button {
                  display: flex;
                }
              }
            }
          }
        }

        .ele-post-atrial-stimulation {
          .ele-post-atrial-stimulation-content {
            margin-top: 30px;
            margin-bottom: 30px;
            border-top: 1px solid #c0c4cc;
            .ele-post-atrial-stimulation-content-head {
              .inner-title-label {
                position: relative;
                top: -12px;
                left: 12px;
                padding: 0 10px;
                font-size: 14px;
                text-align: center;
                font-weight: bold;
              }
            }

            .ele-post-atrial-stimulation-content-body {
              .ele-post-atrial-stimulation-content-body-select {
                .input-form {
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: flex-start;
                }

                .input-form-item-row {
                  width: 100%;
                  margin: 0 0.5rem;
                  display: flex;
                  justify-content: flex-start;
                }

                .input-form-item {
                  min-width: 28%;
                  margin: 0.4rem 1.5rem;
                  display: flex;
                  align-items: center;
                  .input_has_unit {
                    margin: 0;
                  }
                }
                @media screen and (max-width: 1180px) {
                  .input-form-item {
                    min-width: 40%;
                  }
                }

                .input-form-item-label {
                  width: 120px;
                }
              }
              .ele-post-atrial-stimulation-content-body-table {
                .ele-post-atrial-stimulation-content-body-table-button {
                  display: flex;
                }
              }
            }
          }
        }

        .ele-post-bypass-description {
          .ele-post-bypass-description-content {
            margin-top: 30px;
            margin-bottom: 30px;
            border-top: 1px solid #c0c4cc;
            .ele-post-bypass-description-content-head {
              .inner-title-label {
                position: relative;
                top: -12px;
                left: 12px;
                padding: 0 10px;
                font-size: 14px;
                text-align: center;
                font-weight: bold;
              }
            }

            .ele-post-bypass-description-content-body {
              .ele-post-bypass-description-content-body-select {
                .input-form {
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: flex-start;
                }

                .input-form-item-row {
                  width: 100%;
                  margin: 0 0.5rem;
                  display: flex;
                  justify-content: flex-start;
                }

                .input-form-item {
                  min-width: 28%;
                  margin: 0.4rem 1.5rem;
                  display: flex;
                  align-items: center;
                  .input_has_unit {
                    margin: 0;
                  }
                }
                @media screen and (max-width: 1180px) {
                  .input-form-item {
                    min-width: 40%;
                  }
                }

                .input-form-item-label {
                  width: 120px;
                }
              }
            }
          }
        }
        .ele-post-tachycardia-description {
          .ele-post-tachycardia-description-content {
            margin-top: 30px;
            margin-bottom: 30px;
            border-top: 1px solid #c0c4cc;
            .ele-post-tachycardia-description-content-head {
              .inner-title-label {
                position: relative;
                top: -12px;
                left: 12px;
                padding: 0 10px;
                font-size: 14px;
                text-align: center;
                font-weight: bold;
              }
            }

            .ele-post-tachycardia-description-content-body {
              .ele-post-tachycardia-description-body-select {
                .input-form {
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: flex-start;
                }

                .input-form-item-row {
                  width: 100%;
                  margin: 0 0.5rem;
                  display: flex;
                  justify-content: flex-start;
                }

                .input-form-item {
                  min-width: 28%;
                  margin: 0.4rem 1.5rem;
                  display: flex;
                  align-items: center;
                  .input_has_unit {
                    margin: 0;
                  }
                }
                @media screen and (max-width: 1180px) {
                  .input-form-item {
                    min-width: 40%;
                  }
                }

                .input-form-item-label {
                  width: 120px;
                }
              }
            }
          }
        }
      }
    }
    .electrophysiology-electrophysiological-standard-test {
      margin-top: 20px;
      margin-bottom: 30px;
      border-style: solid;
      border-width: medium;
      padding: 20px 15px;
      border-radius: 5px;
      position: relative;
      border-color: #ecf0f1;
      background-color: #fff !important;
      box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);
      .electrophysiology-electrophysiological-standard-test-header {
        .inner-title {
          position: absolute;
          top: -12px;
          left: 12px;
          background: #fff;
          padding: 0 10px;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          padding-top: 5px;
        }
      }
      .input-form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
      }

      .input-form-item-row {
        width: 100%;
        margin: 0 0.5rem;
        display: flex;
        justify-content: flex-start;
      }

      .input-form-item {
        min-width: 28%;
        margin: 0.4rem 1.5rem;
        display: flex;
        align-items: center;
        .input_has_unit {
          margin: 0;
        }
      }
      @media screen and (max-width: 1180px) {
        .input-form-item {
          min-width: 40%;
        }
      }

      .input-form-item-label {
        width: 120px;
      }
    }

    .electrophysiology-radiofrequency-ablation {
      margin-top: 20px;
      margin-bottom: 30px;
      border-style: solid;
      border-width: medium;
      padding: 20px 15px;
      border-radius: 5px;
      position: relative;
      border-color: #ecf0f1;
      background-color: #fff !important;
      box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);
      .electrophysiology-radiofrequency-ablation-header {
        .inner-title {
          position: absolute;
          top: -12px;
          left: 12px;
          background: #fff;
          padding: 0 10px;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          padding-top: 5px;
        }
      }
      .input-form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
      }

      .input-form-item-row {
        width: 100%;
        margin: 0 0.5rem;
        display: flex;
        justify-content: flex-start;
      }

      .input-form-item {
        min-width: 28%;
        margin: 0.4rem 1.5rem;
        display: flex;
        align-items: center;
        .input_has_unit {
          margin: 0;
        }
      }
      @media screen and (max-width: 1180px) {
        .input-form-item {
          min-width: 40%;
        }
      }

      .input-form-item-label {
        width: 120px;
      }
    }
    .electrophysiology-electrical-resuscitation {
      margin-top: 20px;
      margin-bottom: 30px;
      border-style: solid;
      border-width: medium;
      padding: 20px 15px;
      border-radius: 5px;
      position: relative;
      border-color: #ecf0f1;
      background-color: #fff !important;
      box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);
      .electrophysiology-electrical-resuscitation-header {
        .inner-title {
          position: absolute;
          top: -12px;
          left: 12px;
          background: #fff;
          padding: 0 10px;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          padding-top: 5px;
        }
      }
      .input-form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
      }

      .input-form-item-row {
        width: 100%;
        margin: 0 0.5rem;
        display: flex;
        justify-content: flex-start;
      }

      .input-form-item {
        min-width: 28%;
        margin: 0.4rem 1.5rem;
        display: flex;
        align-items: center;
        .input_has_unit {
          margin: 0;
        }
      }
      @media screen and (max-width: 1180px) {
        .input-form-item {
          min-width: 40%;
        }
      }

      .input-form-item-label {
        width: 120px;
      }
    }
  }

  .electrophysiology-radiofrequency-ablation-body-left-heart-ear-select {
    margin-top: 20px;
    margin-bottom: 20px;
    border-style: solid;
    border-width: medium;
    border-color: #ecf0f1;
    background-color: #fff !important;
    box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);
    .electrophysiology-radiofrequency-ablation-body-left-heart-ear-select-header {
      .inner-title {
        position: relative;
        top: -12px;
        left: 12px;
        background: #fff;
        padding: 0 10px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .input-form {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .input-form-item-row {
      width: 100%;
      margin: 0 0.5rem;
      display: flex;
      justify-content: flex-start;
    }

    .input-form-item {
      min-width: 28%;
      margin: 0.4rem 1.5rem;
      display: flex;
      align-items: center;
      .input_has_unit {
        margin: 0;
      }
    }
    @media screen and (max-width: 1180px) {
      .input-form-item {
        min-width: 40%;
      }
    }

    .input-form-item-label {
      width: 120px;
    }
  }

  .electrophysiology-consumables {
    margin-top: 20px;
    margin-bottom: 20px;
    border-style: solid;
    border-width: medium;
    border-color: #c0c4cc;
    .electrophysiology-consumables-header {
      .inner-title {
        position: relative;
        top: -12px;
        left: 12px;
        background: #fff;
        padding: 0 10px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
      }
    }

    .except-button {
      height: 3rem;
      line-height: 3rem;
      display: flex;
      .pacemaker-basic-information-head-text {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    .save-button {
      padding-left: 10px;
    }
    .delete-button {
      padding-left: 10px;
    }
    .input-form {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .input-form-item-row {
      width: 100%;
      margin: 0 0.5rem;
      display: flex;
      justify-content: flex-start;
    }

    .input-form-item {
      min-width: 28%;
      margin: 0.4rem 1.5rem;
      display: flex;
      align-items: center;
      .input_has_unit {
        margin: 0;
      }
    }
    @media screen and (max-width: 1180px) {
      .input-form-item {
        min-width: 40%;
      }
    }

    .input-form-item-label {
      width: 120px;
    }
  }
}
.save-button {
  padding-left: 10px;
  margin-right: 0.5rem;
}
</style>

<template>
  <div class="main-content">
    <div class="crt-content">
      <div class="save-button">
        <el-button
          v-show="!isReadOnly"
          type="success"
          size="mini"
          class="commonBtn"
          @click="open_save"
          :disabled="isReadOnly"
        >
          保存
        </el-button>
      </div>

      <el-button @click="isReadOnly = !isReadOnly" size="mini" class="commonBtn" type="primary">
        {{ isReadOnly === true ? '编辑' : '取消' }}
      </el-button>
      <el-button
        icon="el-icon-s-platform"
        @click="infoPreview"
        size="mini"
        class="commonBtn"
        type="primary"
      >
        报告预览
      </el-button>
      <el-button
        icon="el-icon-edit"
        @click="reportSign"
        size="mini"
        class="commonBtn"
        type="success"
      >
        {{ signStatus ? '解签' : '报告签名' }}
      </el-button>
    </div>
    <div class="electrophysiology-main-content">
      <div class="electrophysiology-basic-information" v-loading="loading">
        <div class="electrophysiology-basic-information-body">
          <!-- 电生理 -->
          <el-card style="width: 33%" :body-style="{ padding: '20px' }">
            <div class="input-form-card">
              <div class="input-form-item">
                <div class="input-form-item-label">手术类型</div>
                <el-select
                  v-if="!isReadOnly"
                  v-model="examineType"
                  multiple
                  placeholder="请选择模板"
                  :disabled="isReadOnly"
                >
                  <el-option
                    v-for="item in moduleOpList"
                    :key="item?.dictIndex"
                    :label="item.dictName"
                    :value="item.dictIndex"
                  ></el-option>
                </el-select>
                <el-tag type="info" v-else>{{ electrophysiologyInfo.examineType }}</el-tag>
              </div>
              <div
                class="input-form-item"
                v-show="electrophysiologyInfo.examineType?.includes('房扑射频')"
              >
                <div class="input-form-item-label">房扑类型</div>
                <cqt-select
                  :isReadonly="isReadOnly"
                  activeUrl="/v1/webconsole/comboData/get/EP_AFluType"
                  :value.sync="electrophysiologyInfo.aFluType"
                />
              </div>

              <div
                class="input-form-item"
                v-show="
                  electrophysiologyInfo.examineType?.includes('房扑射频') &&
                  electrophysiologyInfo.aFluType === '左房房扑'
                "
              >
                <div class="input-form-item-label">右房关键峡部</div>
                <cqt-select
                  :isReadonly="isReadOnly"
                  activeUrl="/v1/webconsole/comboData/get/EP_KeyIsthmusRA"
                  :value.sync="electrophysiologyInfo.keyIsthmusRA"
                />
              </div>

              <div
                class="input-form-item"
                v-show="
                  electrophysiologyInfo.examineType?.includes('房扑射频') &&
                  electrophysiologyInfo.aFluType === '右房房扑'
                "
              >
                <div class="input-form-item-label">左房关键峡部</div>
                <cqt-select
                  :isReadonly="isReadOnly"
                  activeUrl="/v1/webconsole/comboData/get/EP_KeyIsthmusLA"
                  :value.sync="electrophysiologyInfo.keyIsthmusLA"
                />
              </div>

              <div
                class="input-form-item"
                v-show="electrophysiologyInfo.examineType?.includes('房扑射频')"
              >
                <div class="input-form-item-label">折返类型</div>
                <cqt-select
                  :isReadonly="isReadOnly"
                  activeUrl="/v1/webconsole/comboData/get/EP_ReentryType"
                  :value.sync="electrophysiologyInfo.reentryType"
                />
              </div>

              <div
                class="input-form-item"
                v-show="
                  electrophysiologyInfo.examineType?.includes('房扑射频') &&
                  electrophysiologyInfo.reentryType
                "
              >
                <div class="input-form-item-label">折返环位置</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadOnly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="text"
                    v-model="electrophysiologyInfo.reentrantLoopSite"
                    size="mini"
                  />
                </div>
              </div>

              <div
                class="input-form-item"
                v-show="electrophysiologyInfo.examineType?.includes('房扑射频')"
              >
                <div class="input-form-item-label">左房压力</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadOnly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="text"
                    v-model="electrophysiologyInfo.lAPressure"
                    size="mini"
                    unit="mGay"
                    min="0"
                  />
                </div>
              </div>

              <div
                class="input-form-item"
                v-show="electrophysiologyInfo.examineType?.includes('房颤射频')"
              >
                <div class="input-form-item-label">房颤类型</div>
                <cqt-select
                  :isReadonly="isReadOnly"
                  activeUrl="/v1/webconsole/comboData/get/EP_AFibType"
                  :value.sync="electrophysiologyInfo.aFibType"
                />
              </div>

              <div
                class="input-form-item"
                v-show="electrophysiologyInfo.examineType?.includes('房颤射频')"
              >
                <div class="input-form-item-label">肺静脉解剖变异</div>
                <cqt-select
                  :isReadonly="isReadOnly"
                  activeUrl="/v1/webconsole/comboData/get/EP_PulVariation"
                  :value.sync="electrophysiologyInfo.pulVariation"
                />
              </div>

              <div
                class="input-form-item"
                v-show="electrophysiologyInfo.examineType?.includes('房颤射频')"
              >
                <div class="input-form-item-label">术中有无转窦</div>
                <cqt-select
                  :isReadonly="isReadOnly"
                  activeUrl="/v1/webconsole/comboData/get/EP_TransSinus"
                  :value.sync="electrophysiologyInfo.transSinus"
                />
              </div>
            </div>
          </el-card>
          <!-- 血管 -->
          <el-card style="width: 33%" :body-style="{ padding: '20px' }">
            <div class="input-form-card">
              <div class="input-form-item">
                <div class="input-form-item-label">房间隔穿刺</div>
                <cqt-select
                  :isReadonly="isReadOnly"
                  activeUrl="/v1/webconsole/comboData/get/EP_RoomPuncture"
                  :value.sync="electrophysiologyInfo.roomPuncture"
                />
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">血管入路</div>
                <cqt-select
                  :isReadonly="isReadOnly"
                  activeUrl="/v1/webconsole/comboData/get/EP_PunctureSite"
                  :value.sync="universalStudy.vascularApproach"
                />
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">入路方式</div>
                <cqt-select
                  :isReadonly="isReadOnly"
                  activeUrl="/v1/webconsole/comboData/get/AO_road_method"
                  :value.sync="universalStudy.approachType"
                />
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">麻醉方式</div>
                <cqt-select
                  :isReadonly="isReadOnly"
                  activeUrl="/v1/webconsole/comboData/get/AO_anesthesia"
                  :value.sync="universalStudy.anesthesiaType"
                />
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">造影剂</div>
                <div>
                  <cqt-input
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    @input="getcontrastAgent"
                    v-model="universalStudy.contrastAgent"
                    size="mini"
                    unit="ml"
                    min="0"
                    max="300"
                    :isReadonly="isReadOnly"
                  >
                    <template slot="append">ml</template>
                  </cqt-input>
                </div>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">射线量</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadOnly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    @input="getrayQuantity"
                    v-model="universalStudy.rayQuantity"
                    size="mini"
                    unit="mGy"
                    min="0"
                    max="500"
                  >
                    <template slot="append">mGy</template>
                  </cqt-input>
                </div>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">曝光时间</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadOnly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    v-model="universalStudy.exposureTime"
                    size="mini"
                    unit="min"
                    min="0"
                  >
                    <template slot="append">min</template>
                  </cqt-input>
                </div>
              </div>
            </div>
          </el-card>
          <!-- 出血 -->
          <el-card style="width: 33%" :body-style="{ padding: '20px' }">
            <div class="input-form-card">
              <div class="input-form-item">
                <div class="input-form-item-label">出血量</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadOnly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    @input="getoutTransAmount"
                    v-model="universalStudy.outTransAmount"
                    size="mini"
                    unit="ml"
                    min="0"
                    max="5000"
                  >
                    <template slot="append">ml</template>
                  </cqt-input>
                </div>
              </div>

              <!-- 输血 -->
              <Transfusion
                :isReadonly="isReadOnly"
                ref="Transfusion"
                :apiOptions="apiOptions"
                :surgeryInfo="universalStudy"
              />

              <div class="input-form-item">
                <div class="input-form-item-label">鱼精蛋白</div>
                <cqt-radio :value.sync="universalStudy.protamine" :isReadonly="isReadOnly">
                  <el-radio label="有">有</el-radio>
                  <el-radio label="无">无</el-radio>
                </cqt-radio>
              </div>
              <div
                class="input-form-item"
                v-show="universalStudy.protamine && universalStudy.protamine !== '无'"
              >
                <div class="input-form-item-label">鱼精蛋白剂量</div>
                <div>
                  <cqt-input
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    @input="getprotamineDose"
                    v-model="universalStudy.protamineDose"
                    size="mini"
                    unit="ml"
                    min="0"
                    max="500"
                    :isReadonly="isReadOnly"
                  ></cqt-input>
                </div>
              </div>

              <!-- 血管闭合方式 -->
              <BloodCloseType
                :isReadonly="isReadOnly"
                ref="BloodCloseType"
                :apiOptions="apiOptions"
                :surgeryInfo="universalStudy"
              />

              <div class="input-form-item">
                <div class="input-form-item-label">术后制动</div>
                <cqt-select
                  :isReadonly="isReadOnly"
                  activeUrl="/v1/webconsole/comboData/get/EP_BrakAreaTime"
                  :value.sync="universalStudy.postOperativeBraking"
                />
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">特殊操作</div>
                <cqt-select
                  :isReadonly="isReadOnly"
                  activeUrl="/v1/webconsole/comboData/get/EP_Intervention"
                  :value.sync="universalStudy.intervention"
                />
              </div>
            </div>
          </el-card>
        </div>
      </div>
      <div v-show="!isReadOnly" class="selectBox">
        <el-card>
          <cqt-checkbox :value.sync="infoList" :isReadonly="isReadOnly">
            <el-checkbox label="术前检查" @change="addPreOperativeExamination"></el-checkbox>
            <el-checkbox label="术后检查" @change="addPostOperativeExamination"></el-checkbox>
            <el-checkbox
              label="电生理标测"
              @change="addElectrophysiologicalStandardTest"
            ></el-checkbox>
            <el-checkbox label="射频消融" @change="addRadiofrequencyAblation"></el-checkbox>
            <el-checkbox label="电复律" @change="addElectricalResuscitation"></el-checkbox>
          </cqt-checkbox>
        </el-card>
      </div>
      <div class="radios-add-components" v-loading="loading">
        <!-- 术前检查 -->
        <div
          v-show="preOperativeExaminationShow"
          class="electrophysiology-pre-operative-examination"
        >
          <div class="electrophysiology-pre-operative-examination-header">
            <span class="inner-title">术前检查</span>
          </div>
          <div class="electrophysiology-pre-operative-examination-body">
            <div class="electrophysiology-pre-operative-examination-body-radios">
              <cqt-checkbox :value.sync="preOperativeExaminationShowList" :isReadonly="isReadOnly">
                <el-checkbox label="心室刺激" @change="addVentricularStimulation"></el-checkbox>
                <el-checkbox label="心房刺激" @change="addAtrialStimulation"></el-checkbox>
                <el-checkbox label="心房拖带" @change="addAtrialDragBand"></el-checkbox>
                <el-checkbox label="心室拖带" @change="addVentricularDragBand"></el-checkbox>
                <el-checkbox label="心室RS2刺激" @change="addRS2Stimulation"></el-checkbox>
                <el-checkbox
                  label="希氏束旁刺激"
                  @change="addHitchhikerParabolicStimulation"
                ></el-checkbox>
                <el-checkbox label="心动过速描述" @change="addTachycardiaDescription"></el-checkbox>
                <el-checkbox label="旁道描述" @change="addBypassDescription"></el-checkbox>
              </cqt-checkbox>
            </div>

            <div class="ele-ventricular-stimulation" v-show="ventricularStimulationShow">
              <div class="ele-ventricular-stimulation-content">
                <div class="ele-ventricular-stimulation-content-head">
                  <el-tag type="info" size="small" class="inner-title-label">心室刺激</el-tag>
                </div>
                <div class="ele-ventricular-stimulation-content-body">
                  <div class="ele-ventricular-stimulation-content-body-select">
                    <div class="input-form">
                      <div class="input-form-item">
                        <div class="input-form-item-label">异丙肾</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/EP_IsoproVPre"
                          :value.sync="EPCheckInfoPre.isoproVPre"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">ATP</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/EP_ATPVPre"
                          :value.sync="EPCheckInfoPre.aTPVPre"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">阿托品</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/EP_AtropineVPre"
                          :value.sync="EPCheckInfoPre.atropineVPre"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">室房分离周期</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="text"
                            v-model="EPCheckInfoPre.separationCycleVPre"
                            size="mini"
                            unit="ms"
                            min="0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- 术前检查心室刺激列表 -->
                  <div class="ele-ventricular-stimulation-content-body-table">
                    <div class="ele-ventricular-stimulation-content-body-table-list">
                      <div style="margin: 10px 0">
                        <el-button
                          type="primary"
                          size="mini"
                          v-debounce="_preVentricularInsert"
                          class="commonBtn"
                          style="margin: 0 10px; color: #fff"
                          :disabled="isReadOnly"
                        >
                          添加
                        </el-button>
                        <el-button
                          type="primary"
                          size="mini"
                          class="commonBtn"
                          style="color: #fff"
                          v-debounce="open_preVentricularDelete"
                          :disabled="isReadOnly"
                        >
                          删除
                        </el-button>
                      </div>
                      <el-table
                        :data="ventricularStimulationTableDataPre"
                        class="inner-table"
                        highlight-current-row
                        :header-cell-style="{ padding: 0 }"
                        :cell-style="{ textAlign: 'center' }"
                        @row-click="_rowClick"
                        :key="tableKey"
                        height="200px"
                        style="width: 99%"
                        border
                      >
                        <el-table-column
                          prop="inductTypeVPre"
                          label="诱发方式"
                          width="180"
                        ></el-table-column>
                        <el-table-column
                          prop="perimVPre"
                          label="周长（ms）"
                          width="180"
                        ></el-table-column>
                        <el-table-column prop="inductResultVPre" label="诱发结果"></el-table-column>
                        <el-table-column
                          prop="tachInductTypeVPre"
                          label="诱发的心动过速类型"
                        ></el-table-column>

                        <el-table-column
                          prop="activeSequenceVPre"
                          label="冠状窦激动顺序"
                        ></el-table-column>
                        <el-table-column prop="vARelationVPre" label="VA关系"></el-table-column>
                      </el-table>

                      <pre-ventricular-dialog
                        :initDefaultData="defaultData"
                        ref="preVentricularDialog"
                        @preVSave="preVentricularSave"
                      ></pre-ventricular-dialog>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ele-atrial-stimulation" v-show="atrialStimulationShow">
              <!-- 心房刺激 -->
              <div class="ele-atrial-stimulation-content">
                <div class="ele-atrial-stimulation-content-head">
                  <el-tag type="info" size="small" class="inner-title-label">心房刺激</el-tag>
                </div>
                <div class="ele-atrial-stimulation-content-body">
                  <div class="ele-atrial-stimulation-content-body-select">
                    <div class="input-form">
                      <div class="input-form-item">
                        <div class="input-form-item-label">异丙肾</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/EP_IsoproAPre"
                          :value.sync="EPCheckInfoPre.isoproAPre"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">阿托品</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/EP_IsoproAPre"
                          :value.sync="EPCheckInfoPre.atropineAPre"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">窦房结恢复时间</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="text"
                            v-model="EPCheckInfoPre.recoveryTimeAPre"
                            size="mini"
                            unit="ms"
                            min="0"
                          />
                        </div>
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">文氏点</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="text"
                            v-model="EPCheckInfoPre.venturiPointAPre"
                            size="mini"
                            unit="ms"
                            min="0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- 术前检查心房刺激列表 -->
                  <div class="ele-atrial-stimulation-content-body-table">
                    <div class="ele-atrial-stimulation-content-body-table-list">
                      <div style="margin: 10px 0">
                        <el-button
                          type="primary"
                          size="mini"
                          v-debounce="_preAtrialInsert"
                          class="commonBtn"
                          style="margin: 0 10px; color: #fff"
                          :disabled="isReadOnly"
                        >
                          添加
                        </el-button>
                        <el-button
                          type="primary"
                          size="mini"
                          class="commonBtn"
                          style="color: #fff"
                          v-debounce="open_preAtrialDelete"
                          :disabled="isReadOnly"
                        >
                          删除
                        </el-button>
                      </div>

                      <el-table
                        :data="atrialStimulationTableDataPre"
                        class="inner-table"
                        highlight-current-row
                        :header-cell-style="{ padding: 0 }"
                        @row-click="_rowClick"
                        :key="tableKey"
                        height="200px"
                        style="width: 99%"
                        border
                        :cell-style="{ textAlign: 'center' }"
                      >
                        <el-table-column
                          prop="inductTypeAPre"
                          label="诱发方式"
                          width="180"
                        ></el-table-column>
                        <el-table-column
                          prop="perimAPre"
                          label="周长（ms）"
                          width="180"
                        ></el-table-column>
                        <el-table-column prop="inductResultAPre" label="诱发结果"></el-table-column>
                        <el-table-column
                          prop="tachInductTypeAPre"
                          label="诱发的心动过速类型"
                        ></el-table-column>

                        <el-table-column
                          prop="activeSequenceAPre"
                          label="冠状窦激动顺序"
                        ></el-table-column>
                        <el-table-column prop="vARelationAPre" label="VA关系"></el-table-column>
                      </el-table>
                      <pre-atrial-dialog
                        :initDefaultData="defaultData"
                        ref="preAtrialDialog"
                        @preASave="preAtrialSave"
                      ></pre-atrial-dialog>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 心房拖带 -->
            <div class="ele-atrial-drag-band" v-show="atrialDragBandShow">
              <div class="ele-atrial-drag-band-content">
                <div class="ele-atrial-drag-band-content-head">
                  <el-tag type="info" size="small" class="inner-title-label">心房拖带</el-tag>
                </div>
                <div class="ele-atrial-drag-band-content-body">
                  <div class="ele-atrial-drag-band-content-body-select">
                    <div class="input-form">
                      <div class="input-form-item">
                        <div class="input-form-item-label">电极位置</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/EP_ElecSiteRS2"
                          :value.sync="EPCheckInfoPre.elecSiteA"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">拖带结果</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/EP_TowResultA"
                          :value.sync="EPCheckInfoPre.towResultA"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">起搏后间期PPI</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="text"
                            v-model="EPCheckInfoPre.ppia"
                            size="mini"
                            unit="ms"
                            min="0"
                          />
                        </div>
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">刺激周长</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="text"
                            v-model="EPCheckInfoPre.perimA"
                            size="mini"
                            unit="ms"
                            min="0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 心室拖带 -->
            <div class="ele-ventricular-drag-band" v-show="ventricularDragBandShow">
              <div class="ele-ventricular-drag-band-content">
                <div class="ele-ventricular-drag-band-content-head">
                  <el-tag type="info" size="small" class="inner-title-label">心室拖带</el-tag>
                </div>
                <div class="ele-ventricular-drag-band-content-body">
                  <div class="ele-ventricular-drag-band-content-body-select">
                    <div class="input-form">
                      <div class="input-form-item">
                        <div class="input-form-item-label">电极位置</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/EP_ElecSiteRS2"
                          :value.sync="EPCheckInfoPre.elecSiteV"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">拖带结果</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/EP_TowResultV"
                          :value.sync="EPCheckInfoPre.towResultV"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">起搏后间期PPI</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="text"
                            v-model="EPCheckInfoPre.ppiv"
                            size="mini"
                            unit="ms"
                            min="0"
                          />
                        </div>
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">刺激周长</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="text"
                            v-model="EPCheckInfoPre.perimV"
                            size="mini"
                            unit="ms"
                            min="0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 心室RS2刺激 -->
            <div class="ele-RS2" v-show="rS2StimulationShow">
              <div class="ele-RS2-content">
                <div class="ele-RS2-content-head">
                  <el-tag type="info" size="small" class="inner-title-label">心室RS2刺激</el-tag>
                </div>
                <div class="ele-RS2-content-body">
                  <div class="ele-RS2-content-body-select">
                    <div class="input-form">
                      <div class="input-form-item">
                        <div class="input-form-item-label">电极位置</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/EP_ElecSiteRS2"
                          :value.sync="EPCheckInfoPre.elecSiteRS2"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">A波是否被提前</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/EP_AWaveAdvanceRS2"
                          :value.sync="EPCheckInfoPre.aWaveAdvanceRS2"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">RS2周期</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="text"
                            v-model="EPCheckInfoPre.intervalRS2"
                            size="mini"
                            unit="ms"
                            min="0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 希氏束旁刺激 -->
            <div
              class="ele-hitchhiker-parabolic-stimulation"
              v-show="hitchhikerParabolicStimulationShow"
            >
              <div class="ele-hitchhiker-parabolic-stimulation-content">
                <div class="ele-hitchhiker-parabolic-stimulation-content-head">
                  <el-tag type="info" size="small" class="inner-title-label">希氏束旁刺激</el-tag>
                </div>
                <div class="ele-hitchhiker-parabolic-stimulation-content-body">
                  <div class="ele-hitchhiker-parabolic-stimulation-content-body-select">
                    <div class="input-form">
                      <div class="input-form-item">
                        <div class="input-form-item-label">夺获希氏束最小电压</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="text"
                            v-model="EPCheckInfoPre.minVoltageBH"
                            size="mini"
                            unit="mv"
                          />
                        </div>
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">VA周期</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="text"
                            v-model="EPCheckInfoPre.minVIntervalBH"
                            size="mini"
                            unit="ms"
                          />
                        </div>
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">未夺获希氏束最小电压</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="text"
                            v-model="EPCheckInfoPre.maxVoltageBH"
                            size="mini"
                            unit="mv"
                          />
                        </div>
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">VA周期</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="text"
                            v-model="EPCheckInfoPre.maxVIntervalBH"
                            size="mini"
                            unit="ms"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 心动过速描述 -->
            <div class="ele-tachycardia-description" v-show="tachycardiaDescriptionShow">
              <div class="ele-tachycardia-description-content">
                <div class="ele-tachycardia-description-content-head">
                  <el-tag type="info" size="small" class="inner-title-label">心动过速描述</el-tag>
                </div>
                <div class="ele-tachycardia-description-content-body">
                  <div class="ele-tachycardia-description-body-select">
                    <div class="input-form">
                      <div class="input-form-item">
                        <div class="input-form-item-label">周长</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="text"
                            v-model="EPCheckInfoPre.perimTDPre"
                            size="mini"
                            unit="ms"
                            min="0"
                          />
                        </div>
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">QRS</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="text"
                            v-model="EPCheckInfoPre.qrstdpre"
                            size="mini"
                            unit="ms"
                            min="0"
                          />
                        </div>
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">是否与体表心电图一致</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/EP_SameECGTDPre"
                          :value.sync="EPCheckInfoPre.sameECGTDPre"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">室房比例</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/EP_AVRatioPre"
                          :value.sync="EPCheckInfoPre.aVRatioPre"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">是否合并束支阻滞</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/EP_IsBBBPre"
                          :value.sync="EPCheckInfoPre.isBBBPre"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 旁道描述 -->
            <div class="ele-bypass-description" v-show="bypassDescriptionShow">
              <div class="ele-bypass-description-content">
                <div class="ele-bypass-description-content-head">
                  <el-tag type="info" size="small" class="inner-title-label">旁道描述</el-tag>
                </div>
                <div class="ele-bypass-description-content-body">
                  <div class="ele-bypass-description-content-body-select">
                    <div class="input-form">
                      <div class="input-form-item">
                        <div class="input-form-item-label">前传功能</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/EP_PreBypassPre"
                          :value.sync="EPCheckInfoPre.preBypassPre"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">前传旁道位置</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="text"
                            v-model="EPCheckInfoPre.preBypassPreSite"
                            size="mini"
                          />
                        </div>
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">逆传功能</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/EP_BackBypassPre"
                          :value.sync="EPCheckInfoPre.backBypassPre"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">逆传旁道位置</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="text"
                            v-model="EPCheckInfoPre.backBypassPreSite"
                            size="mini"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="postOperativeExaminationShow">
          <el-divider></el-divider>
        </div>
        <!-- 术后检查 -->
        <div
          v-show="postOperativeExaminationShow"
          class="electrophysiology-post-operative-examination"
        >
          <div class="electrophysiology-post-operative-examination-header">
            <span class="inner-title">术后检查</span>
          </div>
          <div class="electrophysiology-post-operative-examination-body">
            <div class="electrophysiology-post-operative-examination-body-radios">
              <cqt-checkbox :value.sync="postOperativeExaminationShowList" :isReadonly="isReadOnly">
                <el-checkbox label="心室刺激" @change="addPostVentricularStimulation"></el-checkbox>
                <el-checkbox label="心房刺激" @change="addPostAtrialStimulation"></el-checkbox>
                <el-checkbox
                  label="心动过速描述"
                  @change="addPostTachycardiaDescription"
                ></el-checkbox>
                <el-checkbox label="旁道描述" @change="addPostBypassDescription"></el-checkbox>
              </cqt-checkbox>
            </div>
            <!-- 术后心室 -->
            <div class="ele-post-ventricular-stimulation" v-show="postVentricularStimulationShow">
              <div class="ele-post-ventricular-stimulation-content">
                <div class="ele-post-ventricular-stimulation-content-head">
                  <el-tag type="info" size="small" class="inner-title-label">心室刺激</el-tag>
                </div>
                <div class="ele-post-ventricular-stimulation-content-body">
                  <div class="ele-post-ventricular-stimulation-body-select">
                    <div class="input-form">
                      <div class="input-form-item">
                        <div class="input-form-item-label">异丙肾</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/EP_IsoproVPost"
                          :value.sync="EPCheckInfoPost.isoproVPost"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">ATP</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/EP_ATPVPost"
                          :value.sync="EPCheckInfoPost.aTPVPost"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">阿托品</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/EP_AtropineVPost"
                          :value.sync="EPCheckInfoPost.atropineVPost"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">室房分离周期</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="text"
                            v-model="EPCheckInfoPost.separationCycleVPost"
                            size="mini"
                            unit="ms"
                            min="0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="ele-post-ventricular-stimulation-content-body-table">
                    <div class="ele-ventricular-stimulation-content-body-table-list">
                      <div style="margin: 10px 0">
                        <el-button
                          type="primary"
                          size="mini"
                          class="commonBtn"
                          v-debounce="_postVentricularInsert"
                          style="margin: 0 10px; color: #fff"
                          :disabled="isReadOnly"
                        >
                          添加
                        </el-button>
                        <el-button
                          type="primary"
                          size="mini"
                          class="commonBtn"
                          style="color: #fff"
                          v-debounce="open_postVentricularDelete"
                          :disabled="isReadOnly"
                        >
                          删除
                        </el-button>
                      </div>
                      <el-table
                        :data="ventricularStimulationTableDataPost"
                        highlight-current-row
                        :header-cell-style="{ padding: 0 }"
                        @row-click="_rowClick"
                        :key="tableKey"
                        height="200px"
                        style="width: 99%"
                        border
                      >
                        <el-table-column
                          prop="inductTypeVPost"
                          label="诱发方式"
                          width="180"
                        ></el-table-column>
                        <el-table-column
                          prop="perimVPost"
                          label="周长（ms）"
                          width="180"
                        ></el-table-column>
                        <el-table-column
                          prop="inductResultVPost"
                          label="诱发结果"
                        ></el-table-column>
                        <el-table-column
                          prop="tachInductTypeVPost"
                          label="诱发的心动过速类型"
                        ></el-table-column>

                        <el-table-column
                          prop="activeSequenceVPost"
                          label="冠状窦激动顺序"
                        ></el-table-column>
                        <el-table-column prop="varelationVPost" label="VA关系"></el-table-column>
                      </el-table>

                      <post-ventricular-dialog
                        :initDefaultData="defaultData"
                        ref="postVentricularDialog"
                        @postVSave="postVentricularSave"
                      ></post-ventricular-dialog>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 术后心房 -->
            <div class="ele-post-atrial-stimulation" v-show="postAtrialStimulationShow">
              <div class="ele-post-atrial-stimulation-content">
                <div class="ele-post-atrial-stimulation-content-head">
                  <el-tag type="info" size="small" class="inner-title-label">心房刺激</el-tag>
                </div>

                <div class="ele-post-atrial-stimulation-content-body">
                  <div class="ele-post-atrial-stimulation-content-body-select">
                    <div class="input-form">
                      <div class="input-form-item">
                        <div class="input-form-item-label">异丙肾</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/EP_IsoproAPost"
                          :value.sync="EPCheckInfoPost.isoproAPost"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">阿托品</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/EP_AtropineAPost"
                          :value.sync="EPCheckInfoPost.atropineAPost"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">窦房结恢复时间</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="text"
                            v-model="EPCheckInfoPost.recoveryTimeAPost"
                            size="mini"
                            unit="ms"
                            min="0"
                          />
                        </div>
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">文氏点</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="text"
                            v-model="EPCheckInfoPost.venturiPointAPost"
                            size="mini"
                            unit="ms"
                            min="0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="ele-atrial-stimulation-content-body-table">
                    <div class="ele-atrial-stimulation-content-body-table-list">
                      <div style="margin: 10px 0">
                        <el-button
                          type="primary"
                          size="mini"
                          class="commonBtn"
                          v-debounce="_postAtrialInsert"
                          style="margin: 0 10px; color: #fff"
                          :disabled="isReadOnly"
                        >
                          添加
                        </el-button>
                        <el-button
                          type="primary"
                          size="mini"
                          class="commonBtn"
                          style="color: #fff"
                          v-debounce="open_postAtrialDelete"
                          :disabled="isReadOnly"
                        >
                          删除
                        </el-button>
                      </div>

                      <el-table
                        :data="atrialStimulationTableDataPost"
                        highlight-current-row
                        :header-cell-style="{ padding: 0 }"
                        @row-click="_rowClick"
                        :key="tableKey"
                        height="200px"
                        style="width: 99%"
                        border
                      >
                        <el-table-column prop="inductTypeAPost" label="诱发方式"></el-table-column>
                        <el-table-column prop="perimAPost" label="周长（ms）"></el-table-column>
                        <el-table-column
                          prop="inductResultAPost"
                          label="诱发结果"
                        ></el-table-column>
                        <el-table-column
                          prop="tachInductTypeAPost"
                          label="诱发的心动过速类型"
                        ></el-table-column>

                        <el-table-column
                          prop="activeSequenceAPost"
                          label="冠状窦激动顺序"
                        ></el-table-column>
                        <el-table-column prop="vARelationAPost" label="VA关系"></el-table-column>
                      </el-table>
                      <post-atrial-dialog
                        :initDefaultData="defaultData"
                        ref="postAtrialDialog"
                        @postASave="postAtrialSave"
                      ></post-atrial-dialog>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ele-post-tachycardia-description" v-show="postTachycardiaDescriptionShow">
              <div class="ele-post-tachycardia-description-content">
                <div class="ele-post-tachycardia-description-content-head">
                  <el-tag type="info" size="small" class="inner-title-label">心动过速描述</el-tag>
                </div>
                <div class="ele-post-tachycardia-description-content-body">
                  <div class="ele-post-tachycardia-description-body-select">
                    <div class="input-form">
                      <div class="input-form-item">
                        <div class="input-form-item-label">周长</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="text"
                            v-model="EPCheckInfoPost.perimTDPost"
                            size="mini"
                            unit="ms"
                            min="0"
                          />
                        </div>
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">QRS</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="text"
                            v-model="EPCheckInfoPost.qRSTDPost"
                            size="mini"
                            unit="ms"
                            min="0"
                          />
                        </div>
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">是否与体表心电图一致</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/EP_SameECGTDPost"
                          :value.sync="EPCheckInfoPost.sameECGTDPost"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">室房比例</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/EP_AVRatioPost"
                          :value.sync="EPCheckInfoPost.aVRatioPost"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">是否合并束支阻滞</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/EP_IsBBBPost"
                          :value.sync="EPCheckInfoPost.isBBBPost"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ele-post-bypass-description" v-show="postBypassDescriptionShow">
              <div class="ele-post-bypass-description-content">
                <div class="ele-post-bypass-description-content-head">
                  <el-tag type="info" size="small" class="inner-title-label">旁道描述</el-tag>
                </div>
                <div class="ele-post-bypass-description-content-body">
                  <div class="ele-post-bypass-description-content-body-select">
                    <div class="input-form">
                      <div class="input-form-item">
                        <div class="input-form-item-label">前传功能</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/EP_PreBypassPost"
                          :value.sync="EPCheckInfoPost.preBypassPost"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">前传旁道位置</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="text"
                            v-model="EPCheckInfoPost.preBypassPostSite"
                            size="mini"
                          />
                        </div>
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">逆传功能</div>
                        <cqt-select
                          :isReadonly="isReadOnly"
                          activeUrl="/v1/webconsole/comboData/get/EP_BackBypassPost"
                          :value.sync="EPCheckInfoPost.backBypassPost"
                        />
                      </div>

                      <div class="input-form-item">
                        <div class="input-form-item-label">逆传旁道位置</div>
                        <div>
                          <cqt-input
                            :isReadonly="isReadOnly"
                            class="input_has_unit"
                            placeholder="请输入"
                            type="text"
                            v-model="EPCheckInfoPost.backBypassPostSite"
                            size="mini"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="electrophysiologicalStandardTestShow">
          <el-divider></el-divider>
        </div>
        <!-- 电生理标测 -->
        <div
          v-show="electrophysiologicalStandardTestShow"
          class="electrophysiology-electrophysiological-standard-test"
        >
          <div class="electrophysiology-electrophysiological-standard-test-header">
            <span class="inner-title">电生理标测</span>
          </div>
          <div class="electrophysiology-electrophysiological-standard-test-body">
            <div class="ele-post-ventricular-stimulation-body-select">
              <div class="input-form">
                <div class="input-form-item">
                  <div class="input-form-item-label">标测技术</div>
                  <cqt-select
                    :isReadonly="isReadOnly"
                    activeUrl="/v1/webconsole/comboData/get/EP_MapTechno"
                    :value.sync="EPElecPhyMapInfo.mapTechno"
                  />
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">有无基质标测</div>
                  <cqt-select
                    :isReadonly="isReadOnly"
                    activeUrl="/v1/webconsole/comboData/get/EP_MatrixDetect"
                    :value.sync="EPElecPhyMapInfo.matrixDetect"
                  />
                </div>
              </div>
            </div>

            <div>
              <div style="margin: 0 0 0.5rem 0.5rem">
                <el-button
                  type="success"
                  class="common-button commonBtn"
                  v-debounce="addElectrophysiological"
                  style="margin-right: 0.5rem"
                  :disabled="isReadOnly"
                >
                  增加
                </el-button>
                <el-button
                  type="warning"
                  class="common-button commonBtn"
                  v-debounce="open_deleteElectrophysiological"
                  :disabled="isReadOnly"
                >
                  删除
                </el-button>
              </div>
              <div
                class="electrophysiology-electrophysiological-standard-test-content-body-table-list"
              >
                <el-table
                  :data="electrophysiologyElectrophysiologicalStandardTestTableData"
                  class="inner-table"
                  highlight-current-row
                  :header-cell-style="{ padding: 0 }"
                  @row-click="_rowClick"
                  :key="tableKey"
                  height="200px"
                  style="width: 99%"
                  border
                >
                  <el-table-column prop="mapElec" label="标测电极" align="center"></el-table-column>
                  <el-table-column prop="mapSite" label="标测位置" align="center"></el-table-column>
                  <el-table-column
                    prop="mapResult"
                    label="标测结果"
                    align="center"
                  ></el-table-column>
                </el-table>

                <standard-test-dialog
                  :initDefaultData="defaultData"
                  ref="standardTestDialog"
                  @standardTestSave="standardTestSave"
                ></standard-test-dialog>
              </div>
            </div>
          </div>
        </div>
        <div v-show="radiofrequencyAblationShow">
          <el-divider></el-divider>
        </div>
        <!-- 射频消融 -->
        <div v-show="radiofrequencyAblationShow" class="electrophysiology-radiofrequency-ablation">
          <div class="electrophysiology-radiofrequency-ablation-header">
            <span class="inner-title">射频消融</span>
          </div>
          <div class="electrophysiology-radiofrequency-ablation-body">
            <div class="electrophysiology-radiofrequency-ablation-body-select">
              <div style="margin: 0 0 1rem 1rem">
                <cqt-radio :value.sync="isExist" :isReadonly="isReadOnly">
                  <el-radio label="有">有</el-radio>
                  <el-radio label="无">无</el-radio>
                </cqt-radio>
              </div>
              <div class="input-form" v-show="this.isExist == '有'">
                <div class="input-form-item">
                  <div class="input-form-item-label">左侧肺静脉是否单圈隔离</div>
                  <cqt-radio
                    :value.sync="EPRadiofreqAblateInfo.isLeftPulmonaryVeinIsolate"
                    :isReadonly="isReadOnly"
                  >
                    <el-radio label="是">是</el-radio>
                    <el-radio label="否">否</el-radio>
                  </cqt-radio>
                </div>
                <div class="input-form-item">
                  <div class="input-form-item-label">左侧肺静脉</div>
                  <cqt-select
                    :isReadonly="isReadOnly"
                    activeUrl="/v1/webconsole/comboData/get/EP_Zcfjmbd"
                    :value.sync="EPRadiofreqAblateInfo.leftPulmonaryVeinFill"
                  />
                </div>
                <div class="input-form-item">
                  <div class="input-form-item-label">右侧静脉是否单圈隔离</div>
                  <cqt-radio
                    :value.sync="EPRadiofreqAblateInfo.isRightPulmonaryVeinIsolate"
                    :isReadonly="isReadOnly"
                  >
                    <el-radio label="是">是</el-radio>
                    <el-radio label="否">否</el-radio>
                  </cqt-radio>
                </div>
                <div class="input-form-item">
                  <div class="input-form-item-label">右侧静脉补点</div>
                  <cqt-select
                    :isReadonly="isReadOnly"
                    activeUrl="/v1/webconsole/comboData/get/EP_Ycfjmbd"
                    :value.sync="EPRadiofreqAblateInfo.rightPulmonaryVeinFill"
                  />
                </div>
              </div>
            </div>

            <div>
              <div style="margin: 0 0 0.5rem 0.5rem">
                <el-button
                  v-debounce="addRadio"
                  type="success"
                  class="common-button commonBtn"
                  style="margin-right: 0.5rem"
                  :disabled="isExist === '无' || isReadOnly"
                >
                  增加
                </el-button>
                <el-button
                  type="warning"
                  class="common-button commonBtn"
                  v-debounce="open_deleteRadio"
                  :disabled="isExist === '无' || isReadOnly"
                >
                  删除
                </el-button>
              </div>
              <div class="electrophysiology-radiofrequency-ablation-content-body-table-list">
                <el-table
                  class="inner-table"
                  :data="electrophysiologyRadiofrequencyAblationTableData"
                  highlight-current-row
                  :header-cell-style="{ padding: 0 }"
                  @row-click="_rowClick"
                  :key="tableKey"
                  height="200px"
                  style="width: 99%"
                  border
                >
                  <el-table-column
                    prop="ablateCath"
                    label="消融导管"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="ablateType"
                    label="消融术式"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="ablateTargetRFA"
                    label="消融靶点/部位"
                    align="center"
                  ></el-table-column>

                  <el-table-column
                    prop="ablateTypeRFA"
                    label="消融方法"
                    align="center"
                  ></el-table-column>

                  <el-table-column
                    prop="temperatureRFA"
                    label="温度（℃）"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="energyRFA"
                    label="能量（W）"
                    align="center"
                  ></el-table-column>

                  <el-table-column
                    prop="dischargeTimeRFA"
                    label="放电时间"
                    align="center"
                  ></el-table-column>

                  <el-table-column prop="airfa" label="AI" align="center"></el-table-column>

                  <el-table-column
                    prop="dischargeTimesRFA"
                    label="放电次数"
                    align="center"
                  ></el-table-column>

                  <el-table-column
                    prop="haveImprove"
                    label="有无改良"
                    align="center"
                  ></el-table-column>

                  <el-table-column
                    prop="ablateSuccess"
                    label="消融是否成功"
                    align="center"
                  ></el-table-column>
                </el-table>

                <radio-frequency-dialog
                  :initDefaultData="defaultData"
                  ref="radioFrequencyDialog"
                  @radioSave="radioFrequencySave"
                ></radio-frequency-dialog>
              </div>
            </div>
          </div>
        </div>
        <!-- 电复律 -->
        <div v-show="electricalResuscitationShow">
          <el-divider></el-divider>
        </div>
        <div
          v-show="electricalResuscitationShow"
          class="electrophysiology-electrical-resuscitation"
        >
          <div class="electrophysiology-electrical-resuscitation-header">
            <span class="inner-title">电复律</span>
          </div>
          <div class="electrophysiology-electrical-resuscitation-body">
            <div class="electrophysiology-electrical-resuscitation-body-select">
              <div class="input-form">
                <div class="input-form-item">
                  <div class="input-form-item-label">总除颤次数</div>
                  <cqt-select
                    :isReadonly="isReadOnly"
                    activeUrl="/v1/webconsole/comboData/get/EP_DefibriTimes"
                    :value.sync="EPCardioversionInfo.defibriTimes"
                  />
                </div>
                <div class="input-form-item">
                  <div class="input-form-item-label">方式</div>
                  <cqt-select
                    :isReadonly="isReadOnly"
                    activeUrl="/v1/webconsole/comboData/get/EP_TypeCard"
                    :value.sync="EPCardioversionInfo.typeCard"
                  />
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">能量</div>
                  <div>
                    <cqt-input
                      :isReadonly="isReadOnly"
                      class="input_has_unit"
                      placeholder="请输入"
                      type="text"
                      v-model="EPCardioversionInfo.energyCard"
                      size="mini"
                      unit="J"
                      min="0"
                    />
                  </div>
                </div>

                <div class="input-form-item">
                  <div class="input-form-item-label">电复律是否成功</div>
                  <cqt-select
                    :isReadonly="isReadOnly"
                    activeUrl="/v1/webconsole/comboData/get/EP_CardioSucess"
                    :value.sync="EPCardioversionInfo.cardioSucess"
                  />
                </div>
                <div class="input-form-item">
                  <div class="input-form-item-label">复律后心脏节律</div>
                  <cqt-select
                    :isReadonly="isReadOnly"
                    activeUrl="/v1/webconsole/comboData/get/EP_HeartRhythm"
                    :value.sync="EPCardioversionInfo.heartRhythm"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="leftEarShow">
        <el-divider></el-divider>
      </div>
      <!-- 左心耳（左心耳封堵术） -->
      <div
        v-show="leftEarShow"
        class="electrophysiology-radiofrequency-ablation-body-left-heart-ear-select"
      >
        <div class="electrophysiology-radiofrequency-ablation-body-left-heart-ear-select-header">
          <span class="inner-title">左心耳（左心耳封堵术）</span>
        </div>
        <div class="input-form">
          <div class="input-form-item">
            <div class="input-form-item-label">心耳形状</div>
            <cqt-select
              :isReadonly="isReadOnly"
              activeUrl="/v1/webconsole/comboData/get/EP_ShapLAA"
              :value.sync="EPLeftAtrialAppendageInfo.shapLAA"
            />
          </div>

          <div class="input-form-item">
            <div class="input-form-item-label">固定区宽度</div>
            <div>
              <cqt-input
                :isReadonly="isReadOnly"
                class="input_has_unit"
                placeholder="请输入"
                type="text"
                v-model="EPLeftAtrialAppendageInfo.fixedWidthLAA"
                size="mini"
                unit="mm"
                min="0"
              />
            </div>
          </div>

          <div class="input-form-item">
            <div class="input-form-item-label">心耳开口宽度</div>
            <div>
              <cqt-input
                :isReadonly="isReadOnly"
                class="input_has_unit"
                placeholder="请输入"
                type="text"
                v-model="EPLeftAtrialAppendageInfo.openWidthLAA"
                size="mini"
                unit="mm"
                min="0"
              />
            </div>
          </div>

          <div class="input-form-item">
            <div class="input-form-item-label">封堵器</div>
            <cqt-select
              :isReadonly="isReadOnly"
              activeUrl="/v1/webconsole/comboData/get/EP_OccluderLAA"
              :value.sync="EPLeftAtrialAppendageInfo.occluderLAA"
            />
          </div>

          <div class="input-form-item">
            <div class="input-form-item-label">有无返流</div>
            <cqt-select
              :isReadonly="isReadOnly"
              activeUrl="/v1/webconsole/comboData/get/EP_HaveRefluxPre"
              :value.sync="EPLeftAtrialAppendageInfo.haveRefluxPre"
            />
          </div>

          <div class="input-form-item">
            <div class="input-form-item-label">返流部位（有无返流）</div>
            <div>
              <cqt-input
                :isReadonly="isReadOnly"
                class="input_has_unit"
                placeholder="请输入"
                type="text"
                v-model="EPLeftAtrialAppendageInfo.refluxSitePre"
                size="mini"
              />
            </div>
          </div>

          <div class="input-form-item">
            <div class="input-form-item-label">返流量（有无返流）</div>
            <div>
              <cqt-input
                :isReadonly="isReadOnly"
                class="input_has_unit"
                placeholder="请输入"
                type="text"
                v-model="EPLeftAtrialAppendageInfo.refluxNumPre"
                size="mini"
                unit="mm"
                min="0"
              />
            </div>
          </div>

          <div class="input-form-item">
            <div class="input-form-item-label">是否更换封堵器</div>
            <cqt-select
              :isReadonly="isReadOnly"
              activeUrl="/v1/webconsole/comboData/get/EP_IsReplaceOccLAA"
              :value.sync="EPLeftAtrialAppendageInfo.isReplaceOccLAA"
            />
          </div>

          <div class="input-form-item">
            <div class="input-form-item-label">更改后封堵器（是否更换封堵器）</div>
            <cqt-select
              :isReadonly="isReadOnly"
              activeUrl="/v1/webconsole/comboData/get/EP_NewOccLAA"
              :value.sync="EPLeftAtrialAppendageInfo.newOccLAA"
            />
          </div>

          <div class="input-form-item">
            <div class="input-form-item-label">更改后有无返流（是否更换封堵器）</div>
            <cqt-select
              :isReadonly="isReadOnly"
              activeUrl="/v1/webconsole/comboData/get/EP_HaveRefluxPost"
              :value.sync="EPLeftAtrialAppendageInfo.haveRefluxPost"
            />
          </div>

          <div class="input-form-item">
            <div class="input-form-item-label">返流部位（更改后有无返流）</div>
            <div>
              <cqt-input
                :isReadonly="isReadOnly"
                class="input_has_unit"
                placeholder="请输入"
                type="text"
                v-model="EPLeftAtrialAppendageInfo.refluxSitePost"
                size="mini"
              />
            </div>
          </div>

          <div class="input-form-item">
            <div class="input-form-item-label">返流量（更改后有无返流）</div>
            <div>
              <cqt-input
                :isReadonly="isReadOnly"
                class="input_has_unit"
                placeholder="请输入"
                type="text"
                v-model="EPLeftAtrialAppendageInfo.refluxNumPost"
                size="mini"
                unit="mm"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-show="atrialFibrillationFreezingShow">
        <el-divider></el-divider>
      </div>
      <!-- 房颤冷冻 -->
      <div v-show="atrialFibrillationFreezingShow" class="electrophysiology-consumables">
        <div class="electrophysiology-consumables-header">
          <span class="inner-title">房颤冷冻</span>
        </div>
        <div class="ele-ventricular-stimulation-content-body-table-list">
          <el-button
            type="success"
            class="common-button commonBtn"
            v-debounce="_freezeInsert"
            style="margin: 0 0 10px 10px"
            :disabled="isReadOnly"
          >
            添加
          </el-button>
          <el-button
            type="warning"
            class="common-button commonBtn"
            v-debounce="open_freezeDelete"
            style="margin: 0 0 10px 10px"
            :disabled="isReadOnly"
          >
            删除
          </el-button>

          <el-table
            :data="freezeTable"
            class="inner-table"
            highlight-current-row
            :header-cell-style="{ padding: 0 }"
            @row-click="_rowClick"
            :key="tableKey"
            height="200px"
            style="width: 99%"
            border
            :cell-style="{ textAlign: 'center' }"
          >
            <el-table-column prop="freezingSite" label="冷冻部位"></el-table-column>
            <el-table-column prop="temperature30" label="30s温度(℃)"></el-table-column>
            <el-table-column prop="temperature60" label="60s温度(℃)"></el-table-column>
            <el-table-column prop="freezingTime" label="冷冻时间"></el-table-column>

            <el-table-column prop="pvitime" label="PVI时间（s）"></el-table-column>
            <el-table-column prop="pvitemperature" label="PVI温度(℃)"></el-table-column>
            <el-table-column prop="minTemperature" label="最低温度(℃)"></el-table-column>
          </el-table>

          <freezing ref="freezing" @freezingSave="freezingSave"></freezing>
        </div>
      </div>

      <!-- 并发症 -->
      <ComplicationForm
        :isReadonly="isReadOnly"
        :complicationData="universalStudy"
        complicationUrl="/v1/webconsole/comboData/get/EP_IntraComplications"
        finalDiagnosisUrl="/v1/webconsole/comboData/get/EP_PostDiagnosis"
        ref="ComplicationForm"
      />

      <!-- 手术经过 -->
      <div class="surgery-procedure">
        <surgery-procedure
          :isReadOnly="isReadOnly"
          :templateData="templateProcedureData"
          :surgeryInfo="universalStudy.studyProcess"
          :operation="operation"
          ref="SurgeryProcedure"
          @infoPreview="infoPreview"
        />
      </div>

      <!-- 手术结果 -->
      <!-- <div class="surgery-result">
        <surgery-result
          :isReadOnly="isReadOnly"
          :templateData="templateResultData"
          :surgeryInfo="universalStudy.studyResult"
          :operation="operation"
          ref="SurgeryResult"
        />
      </div> -->

      <!-- 术后诊疗计划 -->
      <div class="post-operative-medical-diagnosis">
        <post-medical-diagnosis
          :isReadOnly="isReadOnly"
          :templateData="templateDiagnosisData"
          :surgeryInfo="universalStudy"
          :operation="operation"
          ref="PostMedicalDiagnosis"
        />
      </div>
      <!-- 签名 -->
      <sign-report :operation="operation" @signComplete="signComplete" ref="SignReport" />
      <!-- pdf -->
      <el-dialog title="报告详情" :visible.sync="dialogVisible" width="70%" :modal="false">
        <div>
          <iframe :src="this.report_address" style="height: 890px; width: 100%"></iframe>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import SurgeryProcedure from '../components/SurgeryProcedure.vue'
import SurgeryResult from '../components/SurgeryResult.vue'
import ComplicationForm from '../components/ComplicationForm.vue'
import PostMedicalDiagnosis from '../components/PostMedicalDiagnosis.vue'

import SignReport from '../components/SignReport.vue'

import PostAtrialDialog from './components/PostArialdialog.vue'
import PostVentricularDialog from './components/PostVentricularDialog.vue'
import PreAtrialDialog from './components/PreAtrialDialog.vue'
import PreVentricularDialog from './components/PreVentricularDialog.vue'
import StandardTestDialog from './components/StandardTestDialog.vue'
import RadioFrequencyDialog from './components/RadioFrequencyDialog.vue'
import Freezing from './components/Freezing.vue'

import { PDF_HOST } from '../../../utils/globalVariable'

// cqt
import CqtInput from '../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../components/cqt/CqtRadio.vue'
import CqtCheckbox from '../../../components/cqt/CqtCheckbox'

import { differMin } from '../../../utils'

import BloodCloseType from '../components/BloodCloseType.vue'
import Transfusion from '../components/Transfusion.vue'

export default {
  components: {
    SurgeryProcedure,
    SurgeryResult,
    PostMedicalDiagnosis,
    Freezing,
    PostAtrialDialog,
    PostVentricularDialog,
    PreAtrialDialog,
    PreVentricularDialog,
    StandardTestDialog,
    RadioFrequencyDialog,
    SignReport,
    CqtInput,
    CqtSelect,
    CqtRadio,
    CqtCheckbox,
    ComplicationForm,
    BloodCloseType,
    Transfusion
  },
  data() {
    return {
      isReadOnly: false,
      report_address: '',
      dialogVisible: false,
      blood_vessel_type_status: false,
      reportInfo: null,
      signStatus: false,
      loading: false,
      initData: [],
      initStatus: false,
      templateAdviceData: {
        templateName: '术后医嘱'
      },
      templateProcedureData: {
        templateName: '手术经过'
      },
      templateResultData: {
        templateName: '手术结果'
      },
      templateDiagnosisData: {
        templateName: '术后诊疗计划'
      },
      examineTypeValue: '',
      examineTypeIndex: '',
      examineTypeValueList: '',
      selectedRow: null,
      tableKey: null,
      initObj: {},
      allInfo: {
        ZXEFDS: {},
        DSLJCS: {},
        ICEZDXDFCSPXRS: {},
        FCSPXRS: {},
        ZCPDSPXRS: {},
        YCPDSPXRS: {},
        FSJSJLSPXRS: {},
        FPSPXRS: {},
        FSSPXRS: {},
        FZSPXRS: {},
        SXZBXRS: {},
        SSSPXRS: {},
        FCLDXRS: {}
      },
      examineType: [],
      // 基本信息
      electrophysiologyInfo: {
        brakAreaTime: null,
        electrophysiologyIndex: '',
        examineType: null,
        haveAFibFreezing: null,
        haveCardioversion: null,
        haveElecPhyMap: null,
        haveLAA: null,
        havePostCheckInfo: null,
        havePreCheckInfo: null,
        haveRadiofreqAblate: null,
        hemostasis: null,
        intraComplications: null,
        lAPressure: null,
        roomPuncture: null,
        // 房扑
        aFluType: '',
        atrialFlutterIndex: '',
        electrophysiologyIndex: '',
        keyIsthmusLA: null,
        keyIsthmusRA: null,
        reentrantLoopSite: null,
        reentryType: null,
        // 房颤
        aFibType: null,
        atrialFibrillationIndex: '',
        electrophysiologyIndex: '',
        pulVariation: null,
        transSinus: null
      },
      //电生理检查（术前）
      EPCheckInfoPre: {
        aTPVPre: null,
        aVRatioPre: null,
        aWaveAdvanceRS2: null,
        atropineAPre: null,
        atropineVPre: null,
        backBypassPre: null,
        backBypassPreSite: null,
        checkInfoPreIndex: '',
        elecSiteA: null,
        elecSiteRS2: null,
        elecSiteV: null,
        electrophysiologyIndex: '',
        haveAPre: null,
        haveATD: null,
        haveBH: null,
        haveBypassPre: null,
        haveDescriptionPre: null,
        haveRS2: null,
        haveVPre: null,
        haveVTD: null,
        intervalRS2: 0,
        isBBBPre: null,
        isoproAPre: null,
        isoproVPre: null,
        maxVIntervalBH: 0,
        maxVoltageBH: 0,
        minVIntervalBH: 0,
        minVoltageBH: 0,
        perimA: 0,
        perimTDPre: 0,
        perimV: 0,
        ppia: 0,
        ppiv: 0,
        preBypassPre: null,
        preBypassPreSite: null,
        qrstdpre: 0,
        recoveryTimeAPre: 0,
        sameECGTDPre: null,
        separationCycleVPre: 0,
        towResultA: null,
        towResultV: null,
        venturiPointAPre: 0
      },
      //心室刺激表格(术前）
      EPDgvTachInductVPre: {
        activeSequenceVPre: null,
        inductResultVPre: null,
        inductTypeVPre: null,
        perimVPre: null,
        tachInductTypeVPre: null,
        vARelationVPre: null
      },
      //心房刺激表格(术前）
      EPDgvTachInductAPre: {
        activeSequenceAPre: null,
        inductResultAPre: null,
        inductTypeAPre: null,
        perimAPre: null,
        tachInductTypeAPre: null,
        vARelationAPre: null
      },
      //心室刺激表格(术后）
      EPDgvTachInductVPost: {
        activeSequenceVPost: null,
        electrophysiologyIndex: '',
        inductResultVPost: null,
        inductTypeVPost: null,
        perimVPost: null,
        tachInductTypeVPost: null,
        tachInductVPostIndex: '',
        vARelationVPost: null
      },
      //心房刺激表格(术后）
      EPDgvTachInductAPost: {
        activeSequenceAPost: '',
        inductResultAPost: '',
        inductTypeAPost: '',
        perimAPost: '',
        tachInductTypeAPost: '',
        vARelationAPost: ''
      },
      // 电生理检查（术后）
      EPCheckInfoPost: {
        aTPVPost: null,
        aVRatioPost: null,
        atropineAPost: null,
        atropineVPost: null,
        backBypassPost: null,
        backBypassPostSite: null,
        // cssiteTDPost: null,
        checkInfoPostIndex: '',
        electrophysiologyIndex: '',
        haveAPost: null,
        haveBypassPost: null,
        haveDescriptionPost: null,
        haveVPost: null,
        isBBBPost: null,
        isoproAPost: null,
        isoproVPost: null,
        perimTDPost: 0,
        preBypassPost: null,
        preBypassPostSite: null,
        qRSTDPost: 0,
        recoveryTimeAPost: 0,
        sameECGTDPost: null,
        separationCycleVPost: 0,
        venturiPointAPost: 0
      },
      //电生理标测
      EPElecPhyMapInfo: {
        elecPhyMapIndex: '',
        electrophysiologyIndex: '',
        mapTechno: null,
        matrixDetect: null
      },
      //电生理标测（表格）
      EPDgvMap: {
        mapElec: null,
        mapResult: null,
        mapSite: null
      },
      //射频消融
      EPRadiofreqAblateInfo: {
        ablateCath: null,
        ablateSuccess: null,
        ablateType: null,
        electrophysiologyIndex: '',
        isLeftPulmonaryVeinIsolate: null,
        isRightPulmonaryVeinIsolate: null,
        leftPulmonaryVeinFill: null,
        radiofreqAblateIndex: '',
        rightPulmonaryVeinFill: null
      },
      // 射频消融(表格)
      EPDgvTargets: {
        ablateCath: null,
        ablateSuccess: null,
        ablateTargetRFA: null,
        ablateType: null,
        ablateTypeRFA: null,
        airfa: null,
        dischargeTimeRFA: null,
        dischargeTimesRFA: null,
        energyRFA: null,
        haveImprove: null,
        temperatureRFA: null
      },
      //左心耳（左心耳封堵术)
      EPLeftAtrialAppendageInfo: {
        electrophysiologyIndex: '',
        fixedWidthLAA: 0,
        haveRefluxPost: null,
        haveRefluxPre: null,
        isReplaceOccLAA: null,
        leftAtrialAppendageIndex: '',
        newOccLAA: null,
        occluderLAA: null,
        openWidthLAA: 0,
        refluxNumPost: 0,
        refluxNumPre: 0,
        refluxSitePost: null,
        refluxSitePre: null,
        shapLAA: null
      },
      //电复律
      EPCardioversionInfo: {
        cardioSucess: null,
        cardioversionIndex: '',
        defibriTimes: null,
        electrophysiologyIndex: '',
        energyCard: 0,
        heartRhythm: null,
        typeCard: null
      },

      ventricularStimulationTableDataPre: [],
      ventricularStimulationTableDataPost: [],
      isExist: '无',
      atrialStimulationTableDataPre: [],
      atrialStimulationTableDataPost: [],
      freezeTable: [],
      electrophysiologyElectrophysiologicalStandardTestTableData: [],
      electrophysiologyRadiofrequencyAblationTableData: [],

      preOperativeExaminationShow: false,
      postOperativeExaminationShow: false,
      electrophysiologicalStandardTestShow: false,
      radiofrequencyAblationShow: false,
      electricalResuscitationShow: false,
      electrophysiologyRadiosList: [],
      preOperativeExaminationShowList: [],
      postOperativeExaminationShowList: [],
      infoList: [],

      // marker
      ventricularStimulationShow: false,
      atrialStimulationShow: false,
      atrialDragBandShow: false,
      ventricularDragBandShow: false,
      rS2StimulationShow: false,
      hitchhikerParabolicStimulationShow: false,
      tachycardiaDescriptionShow: false,
      bypassDescriptionShow: false,
      leftEarShow: false,
      atrialFibrillationFreezingShow: false,

      postVentricularStimulationShow: false,
      postAtrialStimulationShow: false,
      postTachycardiaDescriptionShow: false,
      postBypassDescriptionShow: false,
      surgeryInfo: null,
      operation: 'Electrophysiology',
      operationName: '电生理',
      moduleOpList: [],

      vesselObturator_status: false,
      formList: [],
      // 通用表单
      formData: {
        formId: '',
        id: '',
        items: []
      },
      allDetail: {},
      allInitInfo: {}, // 初始化值
      menuListData: null,
      universalStudy: {
        // 公共字段
        postDiagnosis: undefined, //术后诊断
        studyProcess: undefined, //手术经过
        // studyResult: undefined, //手术结果
        o2: undefined, //吸氧方式
        anesthesiaType: undefined, //麻醉方式
        vascularApproach: undefined, //血管入路
        otherApproachType: undefined, // 其他入路方式
        approachType: undefined, //入路方式
        superHardGW: undefined, //超硬导丝
        contrastAgent: undefined, //造影剂剂量(ml)
        rayQuantity: undefined, //射线量(mGy)
        exposureTime: undefined, //曝光时间(min)
        sheathSize: undefined, //鞘管大小
        sheathGoInTime: undefined, //鞘置入时间
        sheathOutTime: undefined, //鞘拔除时间
        sheathLength: undefined, //鞘置入时长 min
        isBleeding: '否', // 是否出血
        outTransAmount: undefined, //出血量
        transfusion: undefined, //是否输血
        transfusionComponent: undefined, //输血成分
        transfusionAmount: undefined, //输血量
        protamine: undefined, //鱼精蛋白
        protamineDose: undefined, //鱼精蛋白剂量（mg）
        vesslClosure: undefined, //血管闭合方式
        vesselObturator: undefined, //血管闭合器种类
        vesselObturatorOther: undefined, //血管闭合器种类其他
        intervention: undefined, //特殊操作
        pacingThreshold: undefined, //临时起搏阈值
        pacingFrequency: undefined, //临时起搏频率
        pacingOutput: undefined, //临时起搏输出
        pacingPerception: undefined, //临时起搏感知
        patientFeedback: undefined, //术后患者反映
        skinExamination: undefined, //术后皮肤检查
        vascularPulsation: undefined, //术后穿刺处血管搏动
        postOperativeBraking: undefined, //术后制动
        transferMethod: undefined, //转运方式
        postoperativeDestination: undefined, //术后去向
        riskAssessment: undefined, //风险评估
        complication: undefined, //并发症
        postAdvice: undefined, //术后医嘱
        postNotes: undefined, //术后注意事项
        treatmentPlanId: undefined, //术后诊疗计划id
        treatmentPlan: undefined, //术后诊疗,

        otherTransfusionComponent: undefined, //其他输血成分描述,
        wholeBloodVolume: undefined, //全血输血量,
        plasmaVolume: undefined, //血浆输血量,
        plateletVolume: undefined, //血小板输血量,
        redBloodCellVolume: undefined, //红细胞输血量,
        otherVolume: undefined, //其他成分血输血量,
        otherSuperHardGW: null, // 超硬导丝其他描述
        vesslClosureOther: undefined, // 血管闭合方式其他
        // postDiagnosisOther: undefined, // 术后诊断其他
        // complicationOther: undefined, // 并发症其他

        occluderType: undefined, // 封堵器类型
        occluderOtherType: undefined, // 封堵器类型其他
        staplerType: undefined, // 缝合器类型
        staplerOtherType: undefined, // 缝合器类型其他
        pressureTime: undefined // 压迫时间
      },
      defaultData: null,
      itemArr: [
        {
          // 术前检查
          elementId: 'EPCheckInfoPre',
          value: null,
          extendTable: null
        },
        {
          // 术前心室刺激列表
          elementId: 'EPDgvTachInductVPre',
          value: null,
          extendTable: null
        },
        {
          // 术前心房刺激列表
          elementId: 'EPDgvTachInductAPre',
          value: null,
          extendTable: null
        },
        {
          // 术后检查
          elementId: 'EPCheckInfoPost',
          value: null,
          extendTable: null
        },
        {
          // 术后心室刺激列表
          elementId: 'EPDgvTachInductVPost',
          value: null,
          extendTable: null
        },
        {
          // 术后心房刺激列表
          elementId: 'EPDgvTachInductAPost',
          value: null,
          extendTable: null
        },
        {
          // 电生理标测
          elementId: 'EPElecPhyMap',
          value: null,
          extendTable: null
        },
        {
          // 电生理标测
          elementId: 'EPDgvMap',
          value: null,
          extendTable: null
        },
        {
          // 房颤冷冻
          elementId: 'EPDgvAFibFreezing',
          value: null,
          extendTable: null
        },
        {
          // 射频消融
          elementId: 'EPRadiofreqAblate',
          value: null,
          extendTable: null
        },
        {
          // 射频消融列表
          elementId: 'EPDgvTargets',
          value: null,
          extendTable: null
        },
        {
          // 电复律
          elementId: 'EPCardioversion',
          value: null,
          extendTable: null
        },
        {
          // 左心耳
          elementId: 'EPLeftAtrialAppendage',
          value: null,
          extendTable: null
        }
      ],
      apiOptions: {
        vesslClosureUrl: 'EP_vessel_closure', // 血管闭合方式
        vesselObturatorUrl: 'EP_vessel_obturator', // 闭合器
        occluder_typeUrl: 'occluder_type', // 封堵器
        stapler_typeUrl: 'stapler_type', // 缝合器
        transfusionComponentUrl: 'AO_composition_blood_transfusion' // 输血成分
      }
    }
  },

  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    info: {
      handler(val) {
        if (val && val.uuid) {
          this.getMenuIndex()
        }
      },
      immediate: true
    },

    infoList: {
      handler(val) {
        if (val) {
          if (val.includes('术前检查')) {
            this.electrophysiologyInfo.havePreCheckInfo = '是'
            this.preOperativeExaminationShow = true
          } else {
            this.electrophysiologyInfo.havePreCheckInfo = '否'
            this.preOperativeExaminationShow = false
          }
          if (val.includes('术后检查')) {
            this.electrophysiologyInfo.havePostCheckInfo = '是'
            this.postOperativeExaminationShow = true
          } else {
            this.electrophysiologyInfo.havePostCheckInfo = '否'
            this.postOperativeExaminationShow = false
          }
          if (val.includes('电生理标测')) {
            this.electrophysiologyInfo.haveElecPhyMap = '是'
            this.electrophysiologicalStandardTestShow = true
          } else {
            this.electrophysiologyInfo.haveElecPhyMap = '否'
            this.electrophysiologicalStandardTestShow = false
          }
          if (val.includes('射频消融')) {
            this.electrophysiologyInfo.haveRadiofreqAblate = '是'
            this.radiofrequencyAblationShow = true
          } else {
            this.electrophysiologyInfo.haveRadiofreqAblate = '否'
            this.radiofrequencyAblationShow = false
          }
          if (val.includes('电复律')) {
            this.electrophysiologyInfo.haveCardioversion = '是'
            this.electricalResuscitationShow = true
          } else {
            this.electrophysiologyInfo.haveCardioversion = '否'
            this.electricalResuscitationShow = false
          }
        }
      }
    },
    // 术前
    preOperativeExaminationShowList: {
      handler(val) {
        if (val) {
          if (val.includes('心室刺激')) {
            this.EPCheckInfoPre.haveVPre = '是'
            this.ventricularStimulationShow = true
          } else {
            this.EPCheckInfoPre.haveVPre = '否'
          }
          if (val.includes('心房刺激')) {
            this.EPCheckInfoPre.haveAPre = '是'
            this.atrialStimulationShow = true
          } else {
            this.EPCheckInfoPre.haveAPre = '否'
          }
          if (val.includes('心房拖带')) {
            this.EPCheckInfoPre.haveATD = '是'
            this.atrialDragBandShow = true
          } else {
            this.EPCheckInfoPre.haveATD = '否'
          }
          if (val.includes('心室拖带')) {
            this.EPCheckInfoPre.haveVTD = '是'
            this.ventricularDragBandShow = true
          } else {
            this.EPCheckInfoPre.haveVTD = '否'
          }
          if (val.includes('心室RS2刺激')) {
            this.EPCheckInfoPre.haveRS2 = '是'
            this.rS2StimulationShow = true
          } else {
            this.EPCheckInfoPre.haveRS2 = '否'
          }
          if (val.includes('希氏束旁刺激')) {
            this.EPCheckInfoPre.haveBH = '是'
            this.hitchhikerParabolicStimulationShow = true
          } else {
            this.EPCheckInfoPre.haveBH = '否'
          }
          if (val.includes('心动过速描述')) {
            this.EPCheckInfoPre.haveDescriptionPre = '是'
            this.tachycardiaDescriptionShow = true
          } else {
            this.EPCheckInfoPre.haveDescriptionPre = '否'
          }
          if (val.includes('旁道描述')) {
            this.EPCheckInfoPre.haveBypassPre = '是'
            this.bypassDescriptionShow = true
          } else {
            this.EPCheckInfoPre.haveBypassPre = '否'
          }
        }
      }
    },
    // 术后
    postOperativeExaminationShowList: {
      handler(val) {
        if (val) {
          if (val.includes('心室刺激')) {
            this.EPCheckInfoPost.haveVPost = '是'
            this.postVentricularStimulationShow = true
          } else {
            this.EPCheckInfoPost.haveVPost = '否'
          }
          if (val.includes('心房刺激')) {
            this.EPCheckInfoPost.haveAPost = '是'
            this.postAtrialStimulationShow = true
          } else {
            this.EPCheckInfoPost.haveAPost = '否'
          }
          if (val.includes('心动过速描述')) {
            this.EPCheckInfoPost.haveDescriptionPost = '是'
            this.postTachycardiaDescriptionShow = true
          } else {
            this.EPCheckInfoPost.haveDescriptionPost = '否'
          }
          if (val.includes('旁道描述')) {
            this.EPCheckInfoPost.haveBypassPost = '是'
            this.postBypassDescriptionShow = true
          } else {
            this.EPCheckInfoPost.haveBypassPost = '否'
          }
        }
      }
    },
    // 手术类型变化
    examineType: {
      handler(val) {
        if (val && val.length > 0) {
          let arr = []
          let newArr = []
          for (let j = 0; j < val.length; j++) {
            const element = val[j]
            for (let i = 0; i < this.moduleOpList.length; i++) {
              const ele = this.moduleOpList[i]
              if (element === ele.dictIndex) {
                arr.push({
                  dictName: ele.dictName,
                  dictIndex: ele.dictIndex
                })
                newArr.push(ele.dictName)
              }
            }
          }

          this.electrophysiologyInfo.examineType = newArr.join('|')
          // if (
          //   this.electrophysiologyInfo.examineType?.includes('房扑射频') &&
          //   !this.electrophysiologyInfo.examineType?.includes('房颤射频')
          // ) {
          //   this.electrophysiologyInfo.aFibType = null
          //   this.electrophysiologyInfo.pulVariation = null
          //   this.electrophysiologyInfo.transSinus = null
          // }
          // if (
          //   this.electrophysiologyInfo.examineType?.includes('房颤射频') &&
          //   !this.electrophysiologyInfo.examineType?.includes('房扑射频')
          // ) {
          //   this.electrophysiologyInfo.aFluType = null
          //   this.electrophysiologyInfo.keyIsthmusRA = null
          //   this.electrophysiologyInfo.keyIsthmusLA = null
          //   this.electrophysiologyInfo.reentryType = null
          //   this.electrophysiologyInfo.reentrantLoopSite = null
          //   this.electrophysiologyInfo.lAPressure = null
          // }

          if (arr.length > 0) {
            if (arr.length > 1) {
              for (let i = 0; i < arr.length; i++) {
                const element = arr[i]
                if (element.dictName !== '电生理检查术' && element.dictName !== '左心耳封堵术') {
                  this.examineTypeValue = element.dictName
                  this.examineTypeIndex = element.dictIndex
                  break
                } else {
                  if (i + 1 === arr.length) {
                    this.examineTypeValue = element.dictName
                    this.examineTypeIndex = element.dictIndex
                  }
                }
              }
            } else {
              this.examineTypeValue = arr[0].dictName
              this.examineTypeIndex = arr[0].dictIndex
            }
          }
        } else {
          this.electrophysiologyInfo.examineType = null
        }
      }
    },
    examineTypeValue: {
      handler(val) {
        if (val) {
          if (this.examineTypeValue === '左心耳封堵术') {
            this.leftEarShow = true
            this.electrophysiologyInfo.haveLAA = '是'
          } else {
            this.leftEarShow = false
            this.electrophysiologyInfo.haveLAA = '否'
          }
          if (this.examineTypeValue === '房颤冷冻消融术') {
            this.atrialFibrillationFreezingShow = true
            this.electrophysiologyInfo.haveAFibFreezing = '是'
          } else {
            this.atrialFibrillationFreezingShow = false
            this.electrophysiologyInfo.haveAFibFreezing = '否'
          }
        }
      },
      immediate: true
    },
    isExist: function (val) {
      if (val === '无') {
        this._clearRadiofrequency()
      }
    }
  },

  methods: {
    getrayQuantity() {
      if (this.universalStudy.rayQuantity) {
        if (this.universalStudy.rayQuantity > 500) this.universalStudy.rayQuantity = 500

        if (this.universalStudy.rayQuantity < 0) this.universalStudy.rayQuantity = 0
      }
    },
    getoutTransAmount() {
      if (this.universalStudy.outTransAmount) {
        if (this.universalStudy.outTransAmount > 5000) this.universalStudy.outTransAmount = 5000

        if (this.universalStudy.outTransAmount < 0) this.universalStudy.outTransAmount = 0
      }
    },
    gettransfusionAmount() {
      if (this.universalStudy.transfusionAmount) {
        if (this.universalStudy.transfusionAmount > 5000)
          this.universalStudy.transfusionAmount = 5000

        if (this.universalStudy.transfusionAmount < 0) this.universalStudy.transfusionAmount = 0
      }
    },
    getprotamineDose() {
      if (this.universalStudy.protamineDose) {
        if (this.universalStudy.protamineDose > 500) this.universalStudy.protamineDose = 500

        if (this.universalStudy.protamineDose < 0) this.universalStudy.protamineDose = 0
      }
    },
    getcontrastAgent() {
      if (this.universalStudy.contrastAgent) {
        if (this.universalStudy.contrastAgent > 300) this.universalStudy.contrastAgent = 300

        if (this.universalStudy.contrastAgent < 0) this.universalStudy.contrastAgent = 0
      }
    },
    changeVesselClosure(val) {
      if (val != undefined) {
        // 判断值和变量,如果之和变量相等,则不做操作,否则执行
        if (this.changeValue == val) {
          return
        } else {
          // 储存我所改变的值
          this.changeValue = val
          if (val?.includes('血管闭合器') || val?.includes('血管闭合装置')) {
            // 显示血管闭合器种类
            this.vesselObturator_status = true

            this.blood_vessel_type_status = false
            this.universalStudy.vesslClosureOther = ''
          } else if (val?.includes('其他')) {
            this.blood_vessel_type_status = true

            this.vesselObturator_status = false
            this.universalStudy.vesselObturator = ''
          } else {
            this.vesselObturator_status = false
            this.universalStudy.vesselObturator = ''

            this.blood_vessel_type_status = false
            this.universalStudy.vesslClosureOther = ''
          }
        }
      }
    },
    differMin,
    // 1
    getMenuIndex() {
      if (this.$store.state.menuList.length > 0 && !this.$route.path.includes('follow')) {
        for (let i = 0; i < this.$store.state.menuList.length; i++) {
          const element = this.$store.state.menuList[i]
          if (element.menuCode.includes(this.$route.path)) {
            this.menuListData = element
          }
        }
        this.loading = true
        this.getTable()
      } else {
        let url = `/v1/webconsole/study/operation/menu/${this.info.uuid}`
        this.$api.get(url).then((res) => {
          let menuList = []
          if (res.data && res.data.data) {
            menuList = res.data.data.map((item) => {
              return {
                ...item,
                menuCode: `/Detail/${item.menuCode}`,
                code: item.menuCode
              }
            })
            for (let i = 0; i < menuList.length; i++) {
              const element = menuList[i]
              if (
                element.menuCode.includes(
                  JSON.parse(localStorage.getItem('followPath')).replace('/follow/info', '')
                )
              ) {
                this.menuListData = element
              }
            }
            this.getTable()
          }
        })
      }
    },
    // 2 获取表单id
    getTable() {
      let url = `/v1/webconsole/eform/universal/form/${this.info.uuid}/${this.menuListData.moduleId}`
      this.$api.get(url).then(({ data: res }) => {
        if (res && res.data) {
          this.universalForm = res.data
          this.formData.formId = res.data[0].formId
          let formUrl = `/v1/webconsole/eform/universal/formdata/${this.info.uuid}/${this.menuListData.moduleId}`
          let data = {
            formId: this.formData.formId
          }
          this.$api
            .get(formUrl, data)
            .then(({ data: res }) => {
              if (res && res.data) {
                if (res.data.length !== 0) {
                  this.formList = res.data
                  this.formData.id = res.data[0].id
                  this.getTableData()
                } else {
                  // 如果没有数据则使用初始化
                  this.getDefaultInfo()
                }
                this.getReportInfo()
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    // 3  查询基本数据（公共） 获取表单数据（私有）
    getTableData() {
      let url = `/v1/webconsole/eform/fields/${this.formList[0].formId}/${this.formList[0].id}`
      this.$api.get(url).then(({ data: res }) => {
        let obj = {}
        if (res && res.data) {
          for (let i in res.data) {
            if (!this.universalStudy?.hasOwnProperty(i)) {
              obj[i] = res.data[i].valueString
            }
            if (res.data[i].dataType === 'dataTable') {
              res.data[i].extendTable = []
            }
          }
          this.electrophysiologyInfo = { ...this.electrophysiologyInfo, ...obj }
          this.allDetail = res.data

          this.changeInfoList() // 初始化数据（checkbox）
          this.getExamineType() // 手术类型

          // this.getEPCardioversionInfo() // 电复律
          // this.getEPLeftAtrialAppendageInfo() //左心耳
          // this.getEPRadiofreqAblateInfo() // 射频消融
          // this.getEPElecPhyMapInfo() // 电生理标测
          // this.getEPCheckInfoPre() // 术前检查信息
          // this.getEPCheckInfoPost() // 术后检查
          // this.getRadioTable() // 射频消融（表格）
          // this.getStandardTable() // 电生理标测（表格）
          // this.getPreATable() // 术前心房刺激列表
          // this.getPreVTable() // 术前心室刺激列表
          // this.getPostATable() // 术后心房刺激列表
          // this.getPostVTable() // 术后心室刺激列表
          // this.getFreezing() // 房颤冷冻
        }
      })

      // 查询基本数据（公共）
      this.$api
        .get(
          `/v1/webconsole/study/operation/universal/${this.info.uuid}?moduleCode=${this.menuListData.code}`
        )
        .then(({ data: res }) => {
          if (res && res.data) {
            let obj = {}
            for (let i in res.data) {
              if (this.universalStudy?.hasOwnProperty(i)) {
                obj[i] = res.data[i]
              }
            }
            this.universalStudy = { ...this.universalStudy, ...obj }
          }
        })
    },
    // 初始化数据
    getDefaultInfo() {
      if (this.info) {
        this.$api
          .get(
            `/v1/webconsole/eform/universal/init/${this.info.uuid}/${this.menuListData.moduleId}`
          )
          .then((res) => {
            if (res.data && res.data.data) {
              console.log('init', res)
              this.initAll()
              this.initStatus = false
              let obj = {}
              res.data.data.forEach((item) => {
                obj[item.fieldTitle] = item.initValue // 私有字段
                if (this.electrophysiologyInfo?.hasOwnProperty(item.fieldTitle)) {
                  // 基本信息
                  this.electrophysiologyInfo[item.fieldTitle] = item.initValue
                }
                if (this.universalStudy?.hasOwnProperty(item.fieldTitle)) {
                  // 公共字段
                  this.universalStudy[item.fieldTitle] = item.initValue
                }
                if (this.EPCheckInfoPre?.hasOwnProperty(item.fieldTitle)) {
                  // 心室信息
                  this.EPCheckInfoPre[item.fieldTitle] = item.initValue
                }
                if (this.EPCheckInfoPost?.hasOwnProperty(item.fieldTitle)) {
                  // 心房信息
                  this.EPCheckInfoPost[item.fieldTitle] = item.initValue
                }
                if (this.EPElecPhyMapInfo?.hasOwnProperty(item.fieldTitle)) {
                  // 电生理标测
                  this.EPElecPhyMapInfo[item.fieldTitle] = item.initValue
                }
                if (this.EPRadiofreqAblateInfo?.hasOwnProperty(item.fieldTitle)) {
                  // 射频消融
                  this.EPRadiofreqAblateInfo[item.fieldTitle] = item.initValue
                }
                if (this.EPCardioversionInfo?.hasOwnProperty(item.fieldTitle)) {
                  // 电复律
                  this.EPCardioversionInfo[item.fieldTitle] = item.initValue
                }
                if (this.EPLeftAtrialAppendageInfo?.hasOwnProperty(item.fieldTitle)) {
                  // 左心耳（左心耳封堵术）
                  this.EPLeftAtrialAppendageInfo[item.fieldTitle] = item.initValue
                }
              })
              this.defaultData = { ...obj }
              this.getExamineType() // 手术类型
            }
          })
      }
    },
    getReportInfo() {
      this.$api
        .get(`/v1/webconsole/report/list/doctor/${this.info.uuid}/${this.menuListData.code}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.reportInfo = res.data.data
            if (res.data.data?.signed === 'Y') {
              this.signStatus = true
            }
          }
        })
    },
    // 签名
    reportSign() {
      // 解签
      if (this.signStatus) {
        this.$refs.SignReport.showRelievePwdDialog()
      } else {
        this._save(() => {
          this.$refs.SignReport.getReportSignType()
        })
      }
    },
    signComplete(val) {
      this.signStatus = val
    },
    // 报告
    infoPreview() {
      const pdfLoading = this.$loading({
        lock: true,
        text: '报告加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        fullscreen: false
      })
      this.report_address = null

      this.$api
        .get(`/v1/webconsole/report/list/doctor/${this.info.uuid}/${this.menuListData.code}`)
        .then(
          (res) => {
            pdfLoading.close()
            if (!res.data.data || res.data.data?.signed === 'N') {
              // 生成手术报告单
              this._save(() => {
                this.$api
                  .get(
                    `/v1/webconsole/report/save/doctor/${this.info.uuid}/${this.menuListData.code}`
                  )
                  .then(
                    (response) => {
                      pdfLoading.close()
                      if (response.data && response.data.data) {
                        if (!response.data.data.pdfFileName) {
                          return this.$message.info('暂无报告')
                        }
                        var s = encodeURI(response.data.data.pdfFileName)
                        if (response.data.data.pdfFileName.includes('http')) {
                          this.report_address = s
                        } else {
                          this.report_address =
                            PDF_HOST + `/api/v1/webconsole/downloadcenter/download${s}`
                        }
                        this.dialogVisible = true
                      } else {
                        return this.$message.info('暂无报告')
                      }
                    },
                    () => {
                      pdfLoading.close()
                      return this.$message.error('生成报告出错！')
                    }
                  )
                  .catch((err) => {
                    pdfLoading.close()
                    return this.$message.error('生成报告出错！')
                  })
              })
            } else {
              var s = encodeURIComponent(res.data.data.pdfFileName)
              if (res.data.data.pdfFileName.includes('http')) {
                this.report_address = s
              } else {
                this.report_address = PDF_HOST + `/api/v1/webconsole/downloadcenter/download${s}`
              }
              this.dialogVisible = true
            }
          },
          () => {
            pdfLoading.close()
            return this.$message.error('生成报告出错！')
          }
        )
    },

    getExamineType() {
      this.$api
        .get('/v1/webconsole/study/puboperationlist', {
          moduleCode: this.operation
        })
        .then(
          (res) => {
            if (res.data && res.data.data) {
              let arr = []
              res.data.data.map((item) => {
                arr.push({ dictName: item.dictName, dictIndex: item.dictIndex })
              })
              this.moduleOpList = arr
              if (this.electrophysiologyInfo?.examineType) {
                for (let i = 0; i < this.electrophysiologyInfo.examineType.split('|').length; i++) {
                  const element = this.electrophysiologyInfo.examineType.split('|')[i]
                  for (let j = 0; j < this.moduleOpList.length; j++) {
                    const ele = this.moduleOpList[j]
                    if (element === ele.dictName) {
                      this.examineType.push(ele.dictIndex)
                    }
                  }
                }
              }
            }
          },
          (err) => console.log(err)
        )
    },
    // 初始化重置数据
    initAll() {
      // 基本信息
      this.electrophysiologyInfo.brakAreaTime = null
      this.electrophysiologyInfo.haveAFibFreezing = null
      this.electrophysiologyInfo.haveCardioversion = null
      this.electrophysiologyInfo.haveElecPhyMap = null
      this.electrophysiologyInfo.haveLAA = null
      this.electrophysiologyInfo.havePostCheckInfo = null
      this.electrophysiologyInfo.havePreCheckInfo = null
      this.electrophysiologyInfo.haveRadiofreqAblate = null
      this.electrophysiologyInfo.hemostasis = null
      this.electrophysiologyInfo.intraComplications = null
      this.electrophysiologyInfo.lAPressure = null
      this.electrophysiologyInfo.postDiagnosis = null
      this.electrophysiologyInfo.punctureSite = null
      this.electrophysiologyInfo.roomPuncture = null
      this.EPCheckInfoPre = Object.assign({}, this.$options.data().EPCheckInfoPre)
      this.EPDgvTachInductVPre = Object.assign({}, this.$options.data().EPDgvTachInductVPre)
      this.EPDgvTachInductAPre = Object.assign({}, this.$options.data().EPDgvTachInductAPre)
      this.EPCheckInfoPost = Object.assign({}, this.$options.data().EPCheckInfoPost)
      this.EPDgvTachInductVPost = Object.assign({}, this.$options.data().EPDgvTachInductVPost)
      this.EPDgvTachInductAPost = Object.assign({}, this.$options.data().EPDgvTachInductAPost)
      this.EPElecPhyMapInfo = Object.assign({}, this.$options.data().EPElecPhyMapInfo)
      this.EPDgvMap = Object.assign({}, this.$options.data().EPDgvMap)
      this.EPRadiofreqAblateInfo = Object.assign({}, this.$options.data().EPRadiofreqAblateInfo)
      this.EPDgvTargets = Object.assign({}, this.$options.data().EPDgvTargets)
      this.EPLeftAtrialAppendageInfo = Object.assign(
        {},
        this.$options.data().EPLeftAtrialAppendageInfo
      )
      this.EPCardioversionInfo = Object.assign({}, this.$options.data().EPCardioversionInfo)
    },

    _rowClick(row) {
      this.selectedRow = row
    },
    // 射频消融表格
    radioFrequencySave(val) {
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.electrophysiologyRadiofrequencyAblationTableData.length; i++) {
          const element = this.electrophysiologyRadiofrequencyAblationTableData[i]
          if (val.ids && val.ids === element.ids) {
            isId = false
            this.electrophysiologyRadiofrequencyAblationTableData[i] = val
            this.$set(
              this.electrophysiologyRadiofrequencyAblationTableData,
              i,
              this.electrophysiologyRadiofrequencyAblationTableData[i]
            )
            break
          }
        }
        if (isId) {
          val.ids = Math.random(0, 1000) + Math.random(0, 1000)
          this.electrophysiologyRadiofrequencyAblationTableData.push(val)
        }
      } else {
        for (let i = 0; i < this.electrophysiologyRadiofrequencyAblationTableData.length; i++) {
          const element = this.electrophysiologyRadiofrequencyAblationTableData[i]
          if (val.id === element.id) {
            this.electrophysiologyRadiofrequencyAblationTableData[i] = val
            this.$set(
              this.electrophysiologyRadiofrequencyAblationTableData,
              i,
              this.electrophysiologyRadiofrequencyAblationTableData[i]
            )
            break
          }
        }
      }
    },
    // 电生理标测（表格）
    standardTestSave(val) {
      if (!val.id) {
        let isId = true
        for (
          let i = 0;
          i < this.electrophysiologyElectrophysiologicalStandardTestTableData.length;
          i++
        ) {
          const element = this.electrophysiologyElectrophysiologicalStandardTestTableData[i]
          if (val.ids && val.ids === element.ids) {
            isId = false
            this.electrophysiologyElectrophysiologicalStandardTestTableData[i] = val
            this.$set(
              this.electrophysiologyElectrophysiologicalStandardTestTableData,
              i,
              this.electrophysiologyElectrophysiologicalStandardTestTableData[i]
            )
            break
          }
        }
        if (isId) {
          val.ids = Math.random(0, 1000) + Math.random(0, 1000)
          this.electrophysiologyElectrophysiologicalStandardTestTableData.push(val)
        }
      } else {
        for (
          let i = 0;
          i < this.electrophysiologyElectrophysiologicalStandardTestTableData.length;
          i++
        ) {
          const element = this.electrophysiologyElectrophysiologicalStandardTestTableData[i]
          if (val.id === element.id) {
            this.electrophysiologyElectrophysiologicalStandardTestTableData[i] = val
            this.$set(
              this.electrophysiologyElectrophysiologicalStandardTestTableData,
              i,
              this.electrophysiologyElectrophysiologicalStandardTestTableData[i]
            )
            break
          }
        }
      }
    },
    // 术后检查心房列表
    postAtrialSave(val) {
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.atrialStimulationTableDataPost.length; i++) {
          const element = this.atrialStimulationTableDataPost[i]
          if (val.ids && val.ids === element.ids) {
            isId = false
            this.atrialStimulationTableDataPost[i] = val
            this.$set(
              this.atrialStimulationTableDataPost,
              i,
              this.atrialStimulationTableDataPost[i]
            )
            break
          }
        }
        if (isId) {
          val.ids = Math.random(0, 1000) + Math.random(0, 1000)
          this.atrialStimulationTableDataPost.push(val)
        }
      } else {
        for (let i = 0; i < this.atrialStimulationTableDataPost.length; i++) {
          const element = this.atrialStimulationTableDataPost[i]
          if (val.id === element.id) {
            this.atrialStimulationTableDataPost[i] = val
            this.$set(
              this.atrialStimulationTableDataPost,
              i,
              this.atrialStimulationTableDataPost[i]
            )
            break
          }
        }
      }
    },
    // 术后检查心室列表
    postVentricularSave(val) {
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.ventricularStimulationTableDataPost.length; i++) {
          const element = this.ventricularStimulationTableDataPost[i]
          if (val.ids && val.ids === element.ids) {
            isId = false
            this.ventricularStimulationTableDataPost[i] = val
            this.$set(
              this.ventricularStimulationTableDataPost,
              i,
              this.ventricularStimulationTableDataPost[i]
            )
            break
          }
        }
        if (isId) {
          val.ids = Math.random(0, 1000) + Math.random(0, 1000)
          this.ventricularStimulationTableDataPost.push(val)
        }
      } else {
        for (let i = 0; i < this.ventricularStimulationTableDataPost.length; i++) {
          const element = this.ventricularStimulationTableDataPost[i]
          if (val.id === element.id) {
            this.ventricularStimulationTableDataPost[i] = val
            this.$set(
              this.ventricularStimulationTableDataPost,
              i,
              this.ventricularStimulationTableDataPost[i]
            )
            break
          }
        }
      }
    },
    // 术前检查心房列表
    preAtrialSave(val) {
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.atrialStimulationTableDataPre.length; i++) {
          const element = this.atrialStimulationTableDataPre[i]
          if (val.ids && val.ids === element.ids) {
            isId = false
            this.atrialStimulationTableDataPre[i] = val
            this.$set(this.atrialStimulationTableDataPre, i, this.atrialStimulationTableDataPre[i])
            break
          }
        }
        if (isId) {
          val.ids = Math.random(0, 1000) + Math.random(0, 1000)
          this.atrialStimulationTableDataPre.push(val)
        }
      } else {
        for (let i = 0; i < this.atrialStimulationTableDataPre.length; i++) {
          const element = this.atrialStimulationTableDataPre[i]
          if (val.id === element.id) {
            this.atrialStimulationTableDataPre[i] = val
            this.$set(this.atrialStimulationTableDataPre, i, this.atrialStimulationTableDataPre[i])
            break
          }
        }
      }
    },
    // 术前检查心室列表
    preVentricularSave(val) {
      // this.selectedInfo = {}
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.ventricularStimulationTableDataPre.length; i++) {
          const element = this.ventricularStimulationTableDataPre[i]
          if (val.ids && val.ids === element.ids) {
            isId = false
            this.ventricularStimulationTableDataPre[i] = val
            this.$set(
              this.ventricularStimulationTableDataPre,
              i,
              this.ventricularStimulationTableDataPre[i]
            )
            break
          }
        }
        if (isId) {
          val.ids = Math.random(0, 1000) + Math.random(0, 1000)
          this.ventricularStimulationTableDataPre.push(val)
        }
      } else {
        for (let i = 0; i < this.ventricularStimulationTableDataPre.length; i++) {
          const element = this.ventricularStimulationTableDataPre[i]
          if (val.id === element.id) {
            this.ventricularStimulationTableDataPre[i] = val
            this.$set(
              this.ventricularStimulationTableDataPre,
              i,
              this.ventricularStimulationTableDataPre[i]
            )
            break
          }
        }
      }
    },
    // 房颤冷冻
    freezingSave(val) {
      this.selectedInfo = null
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.freezeTable.length; i++) {
          const element = this.freezeTable[i]
          if (val.ids && val.ids === element.ids) {
            isId = false
            this.freezeTable[i] = val
            this.$set(this.freezeTable, i, this.freezeTable[i])
            break
          }
        }
        if (isId) {
          val.ids = Math.random(0, 1000) + Math.random(0, 1000)
          this.freezeTable.push(val)
        }
      } else {
        for (let i = 0; i < this.freezeTable.length; i++) {
          const element = this.freezeTable[i]
          if (val.id === element.id) {
            this.freezeTable[i] = val
            this.$set(this.freezeTable, i, this.freezeTable[i])
            break
          }
        }
      }
    },

    _preVentricularInsert() {
      this.$refs.preVentricularDialog.Show(this.EPDgvTachInductVPre)
    },

    _freezeInsert() {
      this.$refs.freezing.Show()
    },

    _preAtrialInsert() {
      this.$refs.preAtrialDialog.Show(this.EPDgvTachInductAPre)
    },

    _postVentricularInsert() {
      this.$refs.postVentricularDialog.Show(this.EPDgvTachInductVPost)
    },

    _postAtrialInsert() {
      this.$refs.postAtrialDialog.Show(this.EPDgvTachInductAPost)
    },

    addElectrophysiological() {
      this.$refs.standardTestDialog.Show(this.EPDgvMap)
    },

    addRadio() {
      this.$refs.radioFrequencyDialog.Show(this.EPDgvTargets)
    },

    open_deleteRadio() {
      this.$confirm('是否删除所选项?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deleteRadio()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
        .catch((error) => {
          console.log(error)
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    //删除射频消融
    deleteRadio() {
      if (this.selectedRow) {
        this.$api
          .delete('/v1/webconsole/electrophysiology/deleteEPDgvTargets', {
            dgvTargetsIndex: this.selectedRow.dgvTargetsIndex
          })
          .then(() => {
            this.getRadioTable()
            this.selectedRow = null
          })
      }
    },
    open_preVentricularDelete() {
      this.$confirm('是否删除所选项?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this._preVentricularDelete()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
        .catch((error) => {
          console.log(error)
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    //删除术前-心室刺激
    _preVentricularDelete() {
      if (this.selectedRow) {
        if (this.selectedRow.id) {
          const index = this.ventricularStimulationTableDataPre.findIndex(
            (item) => item.id === this.selectedRow.id
          )
          if (index !== -1) {
            this.ventricularStimulationTableDataPre.splice(index, 1)
          }
        } else {
          const index = this.ventricularStimulationTableDataPre.findIndex(
            (item) => item.ids === this.selectedRow.ids
          )
          if (index !== -1) {
            this.ventricularStimulationTableDataPre.splice(index, 1)
          }
        }
      }
    },
    open_preAtrialDelete() {
      this.$confirm('是否删除所选项?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this._preAtrialDelete()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    //删除术前-心房刺激
    _preAtrialDelete() {
      if (this.selectedRow) {
        if (this.selectedRow.id) {
          const index = this.atrialStimulationTableDataPre.findIndex(
            (item) => item.id === this.selectedRow.id
          )
          if (index !== -1) {
            this.atrialStimulationTableDataPre.splice(index, 1)
          }
        } else {
          const index = this.atrialStimulationTableDataPre.findIndex(
            (item) => item.ids === this.selectedRow.ids
          )
          if (index !== -1) {
            this.atrialStimulationTableDataPre.splice(index, 1)
          }
        }
      }
    },
    open_postVentricularDelete() {
      this.$confirm('是否删除所选项?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this._postVentricularDelete()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    //删除术后心室
    _postVentricularDelete() {
      if (this.selectedRow) {
        if (this.selectedRow.id) {
          const index = this.ventricularStimulationTableDataPost.findIndex(
            (item) => item.id === this.selectedRow.id
          )
          if (index !== -1) {
            this.ventricularStimulationTableDataPost.splice(index, 1)
          }
        } else {
          const index = this.ventricularStimulationTableDataPost.findIndex(
            (item) => item.ids === this.selectedRow.ids
          )
          if (index !== -1) {
            this.ventricularStimulationTableDataPost.splice(index, 1)
          }
        }
      }
    },
    open_postAtrialDelete() {
      this.$confirm('是否删除所选项?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this._postAtrialDelete()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    //删除术后心房
    _postAtrialDelete() {
      if (this.selectedRow) {
        if (this.selectedRow.id) {
          const index = this.ventricularStimulationTableDataPost.findIndex(
            (item) => item.id === this.selectedRow.id
          )
          if (index !== -1) {
            this.ventricularStimulationTableDataPost.splice(index, 1)
          }
        } else {
          const index = this.ventricularStimulationTableDataPost.findIndex(
            (item) => item.ids === this.selectedRow.ids
          )
          if (index !== -1) {
            this.ventricularStimulationTableDataPost.splice(index, 1)
          }
        }
      }
    },
    open_deleteElectrophysiological() {
      this.$confirm('是否删除所选项?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deleteElectrophysiological()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
        .catch((error) => {
          console.log(error)
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    //删除电生理标测
    deleteElectrophysiological() {
      if (this.selectedRow) {
        if (this.selectedRow.id) {
          const index = this.electrophysiologyElectrophysiologicalStandardTestTableData.findIndex(
            (item) => item.id === this.selectedRow.id
          )
          if (index !== -1) {
            this.electrophysiologyElectrophysiologicalStandardTestTableData.splice(index, 1)
          }
        } else {
          const index = this.electrophysiologyElectrophysiologicalStandardTestTableData.findIndex(
            (item) => item.ids === this.selectedRow.ids
          )
          if (index !== -1) {
            this.electrophysiologyElectrophysiologicalStandardTestTableData.splice(index, 1)
          }
        }
      }
    },
    open_freezeDelete() {
      this.$confirm('是否删除所选项?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this._freezeDelete()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
        .catch((error) => {
          console.log(error)
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 房颤冷冻删除
    _freezeDelete() {
      if (this.selectedRow) {
        if (this.selectedRow.id) {
          const index = this.freezeTable.findIndex((item) => item.id === this.selectedRow.id)
          if (index !== -1) {
            this.freezeTable.splice(index, 1)
          }
        } else {
          const index = this.freezeTable.findIndex((item) => item.ids === this.selectedRow.ids)
          if (index !== -1) {
            this.freezeTable.splice(index, 1)
          }
        }
      }
    },
    _clearPreOperation() {
      this.EPCheckInfoPre = {
        aTPVPre: null,
        aVRatioPre: null,
        aWaveAdvanceRS2: null,
        atropineAPre: null,
        atropineVPre: null,
        backBypassPre: null,
        backBypassPreSite: null,
        checkInfoPreIndex: '',
        elecSiteA: null,
        elecSiteRS2: null,
        elecSiteV: null,
        electrophysiologyIndex: '',
        haveAPre: null,
        haveATD: null,
        haveBH: null,
        haveBypassPre: null,
        haveDescriptionPre: null,
        haveRS2: null,
        haveVPre: null,
        haveVTD: null,
        intervalRS2: 0,
        isBBBPre: null,
        isoproAPre: null,
        isoproVPre: null,
        maxVIntervalBH: 0,
        maxVoltageBH: 0,
        minVIntervalBH: 0,
        minVoltageBH: 0,
        perimA: 0,
        perimTDPre: 0,
        perimV: 0,
        ppia: 0,
        ppiv: 0,
        preBypassPre: null,
        preBypassPreSite: null,
        qrstdpre: 0,
        recoveryTimeAPre: 0,
        sameECGTDPre: null,
        separationCycleVPre: 0,
        towResultA: null,
        towResultV: null,
        venturiPointAPre: 0
      }
    },
    _clearPostOperation() {
      this.EPCheckInfoPost = {
        aTPVPost: null,
        aVRatioPost: null,
        atropineAPost: null,
        atropineVPost: null,
        backBypassPost: null,
        backBypassPostSite: null,
        // cssiteTDPost: null,
        checkInfoPostIndex: '',
        electrophysiologyIndex: '',
        haveAPost: null,
        haveBypassPost: null,
        haveDescriptionPost: null,
        haveVPost: null,
        isBBBPost: null,
        isoproAPost: null,
        isoproVPost: null,
        perimTDPost: 0,
        preBypassPost: null,
        preBypassPostSite: null,
        qRSTDPost: 0,
        recoveryTimeAPost: 0,
        sameECGTDPost: null,
        separationCycleVPost: 0,
        venturiPointAPost: 0
      }
    },
    _clearElectrophysiology() {
      this.EPElecPhyMapInfo = {
        elecPhyMapIndex: '',
        electrophysiologyIndex: '',
        mapTechno: null,
        matrixDetect: '无'
      }
    },
    _clearRadiofrequency() {
      this.EPRadiofreqAblateInfo.radiofreqAblateIndex = 0
      this.EPRadiofreqAblateInfo.isLeftPulmonaryVeinIsolate = null
      this.EPRadiofreqAblateInfo.isRightPulmonaryVeinIsolate = null
      this.EPRadiofreqAblateInfo.leftPulmonaryVeinFill = null
      this.EPRadiofreqAblateInfo.rightPulmonaryVeinFill = null
    },
    _clearResuscitation() {
      this.EPCardioversionInfo = {
        cardioSucess: null,
        cardioversionIndex: '',
        defibriTimes: null,
        electrophysiologyIndex: '',
        energyCard: 0,
        heartRhythm: null,
        typeCard: null
      }
    },
    _clearLeft() {
      this.EPLeftAtrialAppendageInfo = {
        electrophysiologyIndex: '',
        fixedWidthLAA: 0,
        haveRefluxPost: null,
        haveRefluxPre: null,
        isReplaceOccLAA: null,
        leftAtrialAppendageIndex: '',
        newOccLAA: null,
        occluderLAA: null,
        openWidthLAA: 0,
        refluxNumPost: 0,
        refluxNumPre: 0,
        refluxSitePost: null,
        refluxSitePre: null,
        shapLAA: null
      }
    },
    open_save() {
      if (this.electrophysiologyInfo.examineType === '') {
        this.$message({
          type: 'warning',
          message: '手术类型不能为空'
        })
        return
      }
      if (
        this.electrophysiologyInfo.examineType?.includes('房扑射频') &&
        !this.electrophysiologyInfo.examineType?.includes('房颤射频')
      ) {
        this.electrophysiologyInfo.aFibType = null
        this.electrophysiologyInfo.pulVariation = null
        this.electrophysiologyInfo.transSinus = null
      }
      if (
        this.electrophysiologyInfo.examineType?.includes('房颤射频') &&
        !this.electrophysiologyInfo.examineType?.includes('房扑射频')
      ) {
        this.electrophysiologyInfo.aFluType = null
        this.electrophysiologyInfo.keyIsthmusRA = null
        this.electrophysiologyInfo.keyIsthmusLA = null
        this.electrophysiologyInfo.reentryType = null
        this.electrophysiologyInfo.reentrantLoopSite = null
        this.electrophysiologyInfo.lAPressure = null
      }

      this.$confirm('是否保存电生理信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this._save()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消保存'
          })
        })
    },
    _save(callback) {
      this.formData.items = [] // 重置item
      this.loading = true
      this.universalStudy = {
        ...this.universalStudy,
        ...this.$refs.BloodCloseType.aorticInfo,
        ...this.$refs.Transfusion.aorticInfo
      }

      let basicAorticInfo = this.electrophysiologyInfo // 全部基础信息
      let complicationForm = this.$refs.ComplicationForm.aorticInfo
      complicationForm = {
        ...complicationForm,
        complication: complicationForm.complication?.join('|'),
        postDiagnosis: complicationForm.postDiagnosis || ''
      } // 并发症
      let differentFields = {}

      for (let item in basicAorticInfo) {
        // 组装私有字段1
        if (!this.universalStudy?.hasOwnProperty(item)) {
          differentFields[item] = basicAorticInfo[item]
        }
      }

      // ----- 私有字段items进行数据组装2 ----
      for (let i in differentFields) {
        this.formData.items.push({
          elementId: i,
          value: differentFields[i],
          extendTable: null
        })
      }

      this.formData.universalStudy = {
        // 基础字段
        ...this.universalStudy,
        ...complicationForm,
        moduleId: this.menuListData.moduleId,
        operationId: this.info.uuid,
        treatmentPlanId: this.$refs.PostMedicalDiagnosis.treatmentPlanId, // 术后诊疗计划
        treatmentPlan: this.$refs.PostMedicalDiagnosis.treatmentPlan, // 术后诊疗计划
        studyProcess: this.$refs.SurgeryProcedure.studyRecord // 手术经过
        // studyResult: this.$refs.SurgeryResult.studyResult // 手术结果
      } // universalStudy字段
      this.handleData()
      let url = `/v1/webconsole/eform/universal/save/${this.info.uuid}/${this.menuListData.moduleId}`
      this.$api
        .post(url, this.formData)
        .then(
          ({ data: data }) => {
            if (data.status === 200) {
              this.formData.id = data.data.id
              this.loading = false
              this.$bus.$emit('saveFormInfo')
              !callback && this.$message.success('保存成功')
            }
          },
          () => {
            this.loading = false
            return !callback && this.$message.error('保存出错')
          }
        )
        .finally(() => {
          callback && callback()
        })
    },

    handleData() {
      let EPCheckInfoPreArr = [] // 术前检查（基本数据）
      let VSTableDataPreArr = [] // 术前心室刺激列表
      let ASTableDataPreArr = [] // 术前心房刺激列表
      let EPCheckInfoPostArr = [] // 术后检查（基本数据）
      let VSTableDataPostArr = [] // 术后心室刺激列表
      let ASTableDataPostArr = [] // 术后心房刺激列表
      let EPElecPhyMapArr = [] // 电生理（基本数据）
      let EPElecPhyMapTableArr = [] // 电生理（列表）

      let EPRadiofreqAblateArr = [] // 射频（基本数据）
      let EPRadiofreqAblateTableArr = [] // 射频消融列表
      let EPCardioversionArr = [] // 电复率（基本数据）
      let EPLeftAtrialAppendageArr = [] // 左心耳封堵术（基本数据）
      let FreezeTableArr = [] // 房颤冷冻

      //术前检查
      if (this.electrophysiologyInfo.havePreCheckInfo === '是') {
        if (this.EPCheckInfoPre.haveVPre === '是') {
          // 心室刺激
          VSTableDataPreArr = this.ventricularStimulationTableDataPre?.map((item, index) => {
            return this.convertElement(item)
          })
        }
        if (this.EPCheckInfoPre.haveAPre === '是') {
          // 心房刺激
          ASTableDataPreArr = this.atrialStimulationTableDataPre?.map((item, index) => {
            return this.convertElement(item)
          })
        }

        //术前检查
        EPCheckInfoPreArr = Array.from(Array(1)).map((_, index) => {
          return this.convertElement(this.EPCheckInfoPre)
        })
      }

      //术后检查
      if (this.electrophysiologyInfo.havePostCheckInfo === '是') {
        // 心室刺激
        if (this.EPCheckInfoPost.haveVPost === '是') {
          VSTableDataPostArr = this.ventricularStimulationTableDataPost?.map((item, index) => {
            return this.convertElement(item)
          })
        }
        // 心房刺激
        if (this.EPCheckInfoPost.haveAPost === '是') {
          ASTableDataPostArr = this.atrialStimulationTableDataPost?.map((item, index) => {
            return this.convertElement(item)
          })
        }

        EPCheckInfoPostArr = Array.from(Array(1)).map((_, index) => {
          return this.convertElement(this.EPCheckInfoPost)
        })
      }

      //电生理
      if (this.electrophysiologyInfo.haveElecPhyMap === '是') {
        // 基本信息
        EPElecPhyMapArr = Array.from(Array(1)).map((_, index) => {
          return this.convertElement(this.EPElecPhyMapInfo)
        })
        // 表格
        EPElecPhyMapTableArr = this.electrophysiologyElectrophysiologicalStandardTestTableData?.map(
          (item, index) => {
            return this.convertElement(item)
          }
        )
      }
      //射频
      if (this.electrophysiologyInfo.haveRadiofreqAblate === '是') {
        // 基本信息
        EPRadiofreqAblateArr = Array.from(Array(1)).map((_, index) => {
          return this.convertElement(this.EPRadiofreqAblateInfo)
        })
        // 表格
        EPRadiofreqAblateTableArr = this.electrophysiologyRadiofrequencyAblationTableData?.map(
          (item, index) => {
            return this.convertElement(item)
          }
        )
      }
      //电复率
      if (this.electrophysiologyInfo.haveCardioversion === '是') {
        EPCardioversionArr = Array.from(Array(1)).map((_, index) => {
          return this.convertElement(this.EPCardioversionInfo)
        })
      }
      if (this.examineTypeValue === '左心耳封堵术') {
        EPLeftAtrialAppendageArr = Array.from(Array(1)).map((_, index) => {
          return this.convertElement(this.EPLeftAtrialAppendageInfo)
        })
      }
      // 房颤冷冻
      if (this.examineTypeValue === '房颤冷冻消融术') {
        FreezeTableArr = this.freezeTable.map((item, index) => {
          return this.convertElement(item)
        })
      }

      this.itemArr.forEach((item) => {
        if (item.elementId === 'EPCheckInfoPre') {
          //术前检查
          item.extendTable = EPCheckInfoPreArr
        }
        if (item.elementId === 'EPDgvTachInductVPre') {
          //术前心室刺激列表
          item.extendTable = VSTableDataPreArr
        }
        if (item.elementId === 'EPDgvTachInductAPre') {
          //术前心房刺激列表
          item.extendTable = ASTableDataPreArr
        }
        if (item.elementId === 'EPCheckInfoPost') {
          //术后检查
          item.extendTable = EPCheckInfoPostArr
        }
        if (item.elementId === 'EPDgvTachInductVPost') {
          //术后心室刺激列表
          item.extendTable = VSTableDataPostArr
        }
        if (item.elementId === 'EPDgvTachInductAPost') {
          //术后心房刺激列表
          item.extendTable = ASTableDataPostArr
        }
        if (item.elementId === 'EPElecPhyMap') {
          //电生理标测
          item.extendTable = EPElecPhyMapArr
        }
        if (item.elementId === 'EPDgvMap') {
          //电生理标测列表
          item.extendTable = EPElecPhyMapTableArr
        }
        if (item.elementId === 'EPDgvAFibFreezing') {
          //房颤冷冻
          item.extendTable = FreezeTableArr
        }
        if (item.elementId === 'EPRadiofreqAblate') {
          //射频消融
          item.extendTable = EPRadiofreqAblateArr
        }
        if (item.elementId === 'EPDgvTargets') {
          //射频消融列表
          item.extendTable = EPRadiofreqAblateTableArr
        }
        if (item.elementId === 'EPCardioversion') {
          //电复律
          item.extendTable = EPCardioversionArr
        }
        if (item.elementId === 'EPLeftAtrialAppendage') {
          //左心耳
          item.extendTable = EPLeftAtrialAppendageArr
        }
      })

      this.formData.items = [...this.formData.items, ...this.itemArr]
    },
    convertElement(element) {
      const arr = []
      delete element?.ids

      for (const i in element) {
        // 直接push即可
        arr.push({
          elementId: i,
          value: element[i],
          extendTable: null
        })
      }

      return arr
    },
    // 术前心房刺激列表
    getPreATable() {
      let url = `/v1/webconsole/eform/extendfields/${this.formData.formId}/${this.allDetail.EPDgvTachInductAPre.id}`
      let formData = new FormData()
      formData.append('elementName', 'EPDgvTachInductAPre')
      this.$api.post(url, formData).then(({ data: res }) => {
        if (res && res.data) {
          this.atrialStimulationTableDataPre = res.data
        }
      })
    },
    // 术前心室刺激列表
    getPreVTable() {
      let url = `/v1/webconsole/eform/extendfields/${this.formData.formId}/${this.allDetail.EPDgvTachInductVPre.id}`
      let formData = new FormData()
      formData.append('elementName', 'EPDgvTachInductVPre')
      this.$api.post(url, formData).then(({ data: res }) => {
        if (res && res.data) {
          this.ventricularStimulationTableDataPre = res.data
        }
      })
    },
    // 术后心房刺激列表
    getPostATable() {
      let url = `/v1/webconsole/eform/extendfields/${this.formData.formId}/${this.allDetail.EPDgvTachInductAPost.id}`
      let formData = new FormData()
      formData.append('elementName', 'EPDgvTachInductAPost')
      this.$api.post(url, formData).then(({ data: res }) => {
        if (res && res.data) {
          this.atrialStimulationTableDataPost = res.data
        }
      })
    },
    // 术后心室刺激列表
    getPostVTable() {
      let url = `/v1/webconsole/eform/extendfields/${this.formData.formId}/${this.allDetail.EPDgvTachInductVPost.id}`
      let formData = new FormData()
      formData.append('elementName', 'EPDgvTachInductVPost')
      this.$api.post(url, formData).then(({ data: res }) => {
        if (res && res.data) {
          this.ventricularStimulationTableDataPost = res.data
        }
      })
    },
    // 房颤冷冻
    getFreezing() {
      let url = `/v1/webconsole/eform/extendfields/${this.formData.formId}/${this.allDetail.EPDgvAFibFreezing.id}`
      let formData = new FormData()
      formData.append('elementName', 'EPDgvAFibFreezing')
      this.$api.post(url, formData).then(({ data: res }) => {
        if (res && res.data) {
          this.freezeTable = res.data
        }
      })
    },
    // 射频消融（表格）
    getRadioTable() {
      let url = `/v1/webconsole/eform/extendfields/${this.formData.formId}/${this.allDetail.EPDgvTargets.id}`
      let formData = new FormData()
      formData.append('elementName', 'EPDgvTargets')
      this.$api.post(url, formData).then(({ data: res }) => {
        if (res && res.data) {
          this.electrophysiologyRadiofrequencyAblationTableData = res.data
        }
      })
    },
    // 电生理标测（表格）
    getStandardTable() {
      let url = `/v1/webconsole/eform/extendfields/${this.formData.formId}/${this.allDetail.EPDgvMap.id}`
      let formData = new FormData()
      formData.append('elementName', 'EPDgvMap')
      this.$api.post(url, formData).then(({ data: res }) => {
        if (res && res.data) {
          this.electrophysiologyElectrophysiologicalStandardTestTableData = res.data
        }
      })
    },
    // 电复律
    getEPCardioversionInfo() {
      let url = `/v1/webconsole/eform/extendfields/${this.formData.formId}/${this.allDetail.EPCardioversion.id}`
      let formData = new FormData()
      formData.append('elementName', 'EPCardioversion')
      this.$api.post(url, formData).then(({ data: res }) => {
        if (res && res.data) {
          for (let item in res.data[0]) {
            if (this.EPCardioversionInfo.hasOwnProperty(item)) {
              this.EPCardioversionInfo[item] = res.data[0][item]
            }
          }
        }
      })
    },
    //左心耳
    getEPLeftAtrialAppendageInfo() {
      let url = `/v1/webconsole/eform/extendfields/${this.formData.formId}/${this.allDetail.EPLeftAtrialAppendage.id}`
      let formData = new FormData()
      formData.append('elementName', 'EPLeftAtrialAppendage')
      this.$api.post(url, formData).then(({ data: res }) => {
        if (res && res.data) {
          for (let item in res.data[0]) {
            if (this.EPLeftAtrialAppendageInfo.hasOwnProperty(item)) {
              this.EPLeftAtrialAppendageInfo[item] = res.data[0][item]
            }
          }
        }
      })
    },
    // 射频消融（基本信息）
    getEPRadiofreqAblateInfo() {
      let url = `/v1/webconsole/eform/extendfields/${this.formData.formId}/${this.allDetail.EPRadiofreqAblate.id}`
      let formData = new FormData()
      formData.append('elementName', 'EPRadiofreqAblate')
      this.$api.post(url, formData).then(({ data: res }) => {
        if (res && res.data) {
          for (let item in res.data[0]) {
            if (this.EPRadiofreqAblateInfo.hasOwnProperty(item)) {
              this.EPRadiofreqAblateInfo[item] = res.data[0][item]
            }
          }
          if (
            this.EPRadiofreqAblateInfo.radiofreqAblateIndex !== '' ||
            this.EPRadiofreqAblateInfo.radiofreqAblateIndex !== null
          ) {
            this.isExist = '有'
          } else {
            this.isExist = '无'
          }
        }
      })
    },
    // 电生理标测
    getEPElecPhyMapInfo() {
      let url = `/v1/webconsole/eform/extendfields/${this.formData.formId}/${this.allDetail.EPElecPhyMap.id}`
      let formData = new FormData()
      formData.append('elementName', 'EPElecPhyMap')
      this.$api.post(url, formData).then(({ data: res }) => {
        if (res && res.data) {
          for (let item in res.data[0]) {
            if (this.EPElecPhyMapInfo.hasOwnProperty(item)) {
              this.EPElecPhyMapInfo[item] = res.data[0][item]
            }
          }
        }
      })
    },
    pushPreList() {
      this.preOperativeExaminationShowList = []
      if (this.EPCheckInfoPre.haveVPre === '是') {
        this.preOperativeExaminationShowList.push('心室刺激')
        this.getPreVTable() // 术前心室刺激列表
      }
      if (this.EPCheckInfoPre.haveAPre === '是') {
        this.preOperativeExaminationShowList.push('心房刺激')
        this.getPreATable() // 术前心房刺激列表
      }
      if (this.EPCheckInfoPre.haveATD === '是') {
        this.preOperativeExaminationShowList.push('心房拖带')
      }
      if (this.EPCheckInfoPre.haveVTD === '是') {
        this.preOperativeExaminationShowList.push('心室拖带')
      }
      if (this.EPCheckInfoPre.haveRS2 === '是') {
        this.preOperativeExaminationShowList.push('心室RS2刺激')
      }
      if (this.EPCheckInfoPre.haveBH === '是') {
        this.preOperativeExaminationShowList.push('希氏束旁刺激')
      }
      if (this.EPCheckInfoPre.haveDescriptionPre === '是') {
        this.preOperativeExaminationShowList.push('心动过速描述')
      }
      if (this.EPCheckInfoPre.haveBypassPre === '是') {
        this.preOperativeExaminationShowList.push('旁道描述')
      }
    },
    pushPostList() {
      this.postOperativeExaminationShowList = []
      if (this.EPCheckInfoPost.haveVPost === '是') {
        this.postOperativeExaminationShowList.push('心室刺激')
        this.getPostVTable() // 术后心室刺激列表
      }
      if (this.EPCheckInfoPost.haveAPost === '是') {
        this.postOperativeExaminationShowList.push('心房刺激')
        this.getPostATable() // 术后心房刺激列表
      }
      if (this.EPCheckInfoPost.haveDescriptionPost === '是') {
        this.postOperativeExaminationShowList.push('心动过速描述')
      }
      if (this.EPCheckInfoPost.haveBypassPost === '是') {
        this.postOperativeExaminationShowList.push('旁道描述')
      }
    },
    changeInfoList() {
      this.infoList = []
      if (this.electrophysiologyInfo.havePreCheckInfo === '是') {
        this.infoList.push('术前检查')
        this.getEPCheckInfoPre() // 术前检查
      }
      if (this.electrophysiologyInfo.havePostCheckInfo === '是') {
        this.infoList.push('术后检查')
        this.getEPCheckInfoPost() // 术后检查
      }
      if (this.electrophysiologyInfo.haveElecPhyMap === '是') {
        this.infoList.push('电生理标测')
        this.getEPElecPhyMapInfo() // 电生理标测
        this.getStandardTable() // 电生理标测（表格）
      }
      if (this.electrophysiologyInfo.haveRadiofreqAblate === '是') {
        this.infoList.push('射频消融')
        this.getEPRadiofreqAblateInfo() // 射频消融
        this.getRadioTable() // 射频消融（表格）
      }
      if (this.electrophysiologyInfo.haveCardioversion === '是') {
        this.infoList.push('电复律')
        this.getEPCardioversionInfo() // 电复律
      }
      // 左心耳
      if (this.electrophysiologyInfo.haveLAA === '是') {
        this.leftEarShow = true
        this.getEPLeftAtrialAppendageInfo() //左心耳
      }
      // 房颤冷冻
      if (this.electrophysiologyInfo.haveAFibFreezing === '是') {
        this.atrialFibrillationFreezingShow === true
        this.getFreezing() // 房颤冷冻
      }
    },
    // 术前检查信息
    getEPCheckInfoPre() {
      let url = `/v1/webconsole/eform/extendfields/${this.formData.formId}/${this.allDetail.EPCheckInfoPre.id}`
      let formData = new FormData()
      formData.append('elementName', 'EPCheckInfoPre')
      this.$api.post(url, formData).then(({ data: res }) => {
        if (res && res.data) {
          for (let item in res.data[0]) {
            if (this.EPCheckInfoPre.hasOwnProperty(item)) {
              this.EPCheckInfoPre[item] = res.data[0][item]
            }
          }

          //  判断哪些checkbox需要展示
          this.pushPreList()
        }
      })
    },

    // 术后检查
    getEPCheckInfoPost() {
      let url = `/v1/webconsole/eform/extendfields/${this.formData.formId}/${this.allDetail.EPCheckInfoPost.id}`
      let formData = new FormData()
      formData.append('elementName', 'EPCheckInfoPost')
      this.$api.post(url, formData).then(({ data: res }) => {
        if (res && res.data) {
          for (let item in res.data[0]) {
            if (this.EPCheckInfoPost.hasOwnProperty(item)) {
              this.EPCheckInfoPost[item] = res.data[0][item]
            }
          }

          // 判断哪些checkbox需要展示
          this.pushPostList()
        }
      })
    },

    addPreOperativeExamination() {
      this.preOperativeExaminationShow = !this.preOperativeExaminationShow
    },
    addPostOperativeExamination() {
      this.postOperativeExaminationShow = !this.postOperativeExaminationShow
    },
    addElectrophysiologicalStandardTest() {
      this.electrophysiologicalStandardTestShow = !this.electrophysiologicalStandardTestShow
    },

    addRadiofrequencyAblation() {
      this.radiofrequencyAblationShow = !this.radiofrequencyAblationShow
    },
    addElectricalResuscitation() {
      this.electricalResuscitationShow = !this.electricalResuscitationShow
    },

    addVentricularStimulation() {
      // 电生理检查(术前)
      this.ventricularStimulationShow = !this.ventricularStimulationShow
    },
    addAtrialStimulation() {
      this.atrialStimulationShow = !this.atrialStimulationShow
    },
    addAtrialDragBand() {
      this.atrialDragBandShow = !this.atrialDragBandShow
    },
    addVentricularDragBand() {
      this.ventricularDragBandShow = !this.ventricularDragBandShow
    },
    addRS2Stimulation() {
      this.rS2StimulationShow = !this.rS2StimulationShow
    },
    addHitchhikerParabolicStimulation() {
      this.hitchhikerParabolicStimulationShow = !this.hitchhikerParabolicStimulationShow
    },
    addTachycardiaDescription() {
      this.tachycardiaDescriptionShow = !this.tachycardiaDescriptionShow
    },
    addBypassDescription() {
      this.bypassDescriptionShow = !this.bypassDescriptionShow
    },
    addPostVentricularStimulation() {
      this.postVentricularStimulationShow = !this.postVentricularStimulationShow
    },
    addPostAtrialStimulation() {
      this.postAtrialStimulationShow = !this.postAtrialStimulationShow
    },
    addPostTachycardiaDescription() {
      this.postTachycardiaDescriptionShow = !this.postTachycardiaDescriptionShow
    },
    addPostBypassDescription() {
      this.postBypassDescriptionShow = !this.postBypassDescriptionShow
    }
  }
}
</script>
